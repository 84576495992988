import CreateSelectionSwitcher from '@/store/functions/array-switch';
import unique from '@/js/functions/unique';

const Gallery = {
  state: () => ({
    galleryPictures: [],
    galleryThumbs: [],

    galleryFilterCategories: [],
    galleryFilterModels: [],

    galleryInfo: {},

    gallerySelectedPictures: [],
    gallerySelectedTags: [],
    gallerySelectedModels: [],
    gallerySelectedCategories: [],

    galleryHasEdited: false,
  }),
  getters: {
    getGalleryPictures: state => state.galleryPictures,
    getGalleryThumbs: state => state.galleryThumbs,

    getGalleryFilterCategories: state => state.galleryFilterCategories,
    getGalleryFilterModels: state => state.galleryFilterModels,

    getGalleryInfo: state => state.galleryInfo,

    getGallerySelectedTags: state => state.gallerySelectedTags,
    getGallerySelectedModels: state => state.gallerySelectedModels,
    getGallerySelectedPictures: state => state.gallerySelectedPictures,
    getGallerySelectedCategories: state => state.gallerySelectedCategories,

    getGalleryHasEdited: state => state.galleryHasEdited,
  },
  mutations: {
    setGalleryInfo: (state, galleryInfo) => (state.galleryInfo = galleryInfo),

    setGalleryHasEdited: (state, value) => (state.galleryHasEdited = value),

    cleanGallerySelectedTags: state => (state.gallerySelectedTags = []),
    cleanGallerySelectedPictures: state => (state.gallerySelectedPictures = []),
    cleanGallerySelectedModels: state => (state.gallerySelectedModels = []),
    cleanGallerySelectedCategories: state => (state.gallerySelectedCategories = []),

    cleanGalleryFilterTags: state => (state.galleryFilterCategories = []),

    setGalleryFilteredTags: (state, tags) => (state.filteredTags = tags),
    setGalleryFilteredModels: (state, models) => (state.galleryFilterModels = models),

    setGalleryPictures: (state, pictures) => (state.galleryPictures = pictures),
    setGalleryThumbs: (state, thumbs) => (state.galleryThumbs = thumbs),

    setGallerySelectedPictures: CreateSelectionSwitcher('gallerySelectedPictures'),
    setGallerySelectedTags: CreateSelectionSwitcher('gallerySelectedTags'),
    setGallerySelectedModels: CreateSelectionSwitcher('gallerySelectedModels'),
    setGallerySelectedCategories: CreateSelectionSwitcher('gallerySelectedCategories'),

    setGalleryNiche: (state, niche = 'straight') => (state.galleryInfo.orientation = { title: niche }),

    setGalleryFilterCategory: CreateSelectionSwitcher('galleryFilterCategories'),
    setGalleryFilterModels: CreateSelectionSwitcher('galleryFilterModels'),

    updateSinglePictureInfo: (state, newPicture) => {
      const i = state.galleryPictures.findIndex(item => item.guid === newPicture.guid);

      if (state.galleryPictures[i]) {
        state.galleryPictures[i].categories = newPicture.categories;
        state.galleryPictures[i].models = newPicture.models;
      }
    },

    unionGalleryLabelsToPictures: state => {
      state.gallerySelectedPictures.forEach(selectedPicture => {
        const index = state.galleryPictures.findIndex(picture => picture.guid === selectedPicture.guid);

        if (~index) {
          state.galleryPictures[index].tags = [];
          state.galleryPictures[index].models = unique([
            ...state.galleryPictures[index].models,
            ...state.gallerySelectedModels,
          ]);
          state.galleryPictures[index].categories = unique([
            ...state.galleryPictures[index].categories,
            ...state.gallerySelectedCategories,
          ]);
        }
      });
    },

    subtractGalleryLabelsFromPictures: state => {
      state.gallerySelectedPictures.forEach(selectedPicture => {
        const index = state.galleryPictures.findIndex(picture => picture.guid === selectedPicture.guid);

        if (~index) {
          state.galleryPictures[index].tags = unique(
            state.galleryPictures[index].tags.filter(tag => {
              return !state.gallerySelectedTags.some(selectedTag => selectedTag.guid === tag.guid);
            })
          );

          state.galleryPictures[index].categories = unique(
            state.galleryPictures[index].categories.filter(category => {
              return !state.gallerySelectedCategories.some(selectedCategory => selectedCategory.guid === category.guid);
            })
          );

          state.galleryPictures[index].models = unique(
            state.galleryPictures[index].models.filter(model => {
              return !state.gallerySelectedModels.some(selectedModel => selectedModel.guid === model.guid);
            })
          );
        }
      });
    },
  },
};

export default Gallery;
