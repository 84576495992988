<template>
  <div class="switch-element" @click="_switch">
    <span class="switch-element__title" v-if="title">
      {{ title }}
    </span>
    <span
      class="switch-element__switch"
      :class="{
        'switch-element__switch--active': enabled,
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'AppSwitch',
  props: {
    enabled: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    _switch() {
      this.$emit('switch', !this.enabled);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';

.switch-element {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  flex-shrink: 0;
}

.switch-element__title {
  font-size: 12px;
  font-weight: bold;
}

.switch-element__switch {
  display: inline-block;
  height: 25px;
  width: 40px;
  border: 1px solid #ccc;
  border-radius: 13px;
  position: relative;
  background-color: #fff;

  @include transition;

  &:hover:not(.switch-element__switch--active) {
    border-color: #aaa;
  }

  &::after {
    content: '';
    display: block;
    height: 21px;
    width: 21px;
    background-color: #ccc;
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 50%;

    @include transition;
  }

  &:hover:not(.switch-element__switch--active)::after {
    background-color: #aaa;
  }

  &--active {
    border-color: lighten(forestgreen, 10%);
  }

  &--active::after {
    background-color: lighten(forestgreen, 10%);
    left: calc(100% - 22px);
  }

  &--active:hover {
    border-color: lighten(forestgreen, 20%);
  }

  &--active:hover::after {
    background-color: lighten(forestgreen, 20%);
  }
}
</style>
