import APIService from '@/js/api/api-service';

export default class UserService {
  token = null;
  static fetchUserInfo = APIService.get('/self');

  static getToken() {
    return this.token || localStorage.getItem('token');
  }

  static isAuth() {
    const isExpired = Date.now() > +localStorage.getItem('tokenExp');
    return !isExpired && !!this.getToken();
  }

  static login = APIService.post('login');

  static logout() {
    localStorage.removeItem('token');
    this.token = null;
  }

  static setToken(token) {
    const expireAt = Date.now() + 24 * 3600 * 1000;

    localStorage.setItem('token', token);
    localStorage.setItem('tokenExp', `${expireAt}`);
    this.token = token;
  }
}
