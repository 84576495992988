import APIService from '@/js/api/api-service';

export default class ChannelsService {
  // Получить список всех каналов
  static getAll = (page, limit, sort = 'title', direction = 'asc') => {
    let url = `channel`;
    let params = '';

    if (sort) {
      params = params + (params.length ? '&' : '?') + `_order_by=${sort} ${direction}`;
    }

    if (page) {
      params = params + (params.length ? '&' : '?') + `_offset=${(page - 1) * limit}`;
    }

    if (limit) {
      params = params + (params.length ? '&' : '?') + `_limit=${limit}`;
    }

    return APIService.get(url + params)();
  };

  // Поиск по имени каналов и (или) первой букве
  static search(query) {
    const url = `channel`;

    let params = `?_filter= title ~ "(?i)${query}"`;

    return APIService.get(url + params)();
  }
}
