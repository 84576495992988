<template>
  <div
    class="gallery-navigation"
    :class="{
      'gallery-navigation--next': orientation === 'right',
    }"
    @click="onClick"
  >
    <svg>
      <use xlink:href="@/assets/icons.svg#arrow"></use>
    </svg>
  </div>
</template>

<script>
import GalleriesHistory from '@/js/etc/galleries-history';

export default {
  name: 'AppGalleryNavigation',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    to: {
      type: Object,
      required: true,
    },
    orientation: {
      type: String,
      default: 'left',
    },
  },
  methods: {
    async onClick() {
      await this.$router.push(this.to).then(() => {
        if (this.orientation === 'right' && !this.index) {
          GalleriesHistory.add(this.to.params.guid);
        }

        this.$router.go(0);
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/colors.scss';

.gallery-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(#222, 0.2);
  padding: 17px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  position: fixed;
  top: 50%;
  z-index: 3;

  &:not(.gallery-navigation--next) {
    left: 10px;
  }

  &:is(.gallery-navigation--next) {
    right: 10px;

    & > svg {
      rotate: -90deg;
    }
  }

  & > svg {
    width: 30px;
    height: 26px;
    rotate: 90deg;
    fill: $themeColor;
    transform: translateY(2px);
  }

  &:hover {
    background-color: rgba(#222, 0.1);
  }

  &:hover > svg {
    fill: $themeColorHover;
  }
}
</style>
