<template>
  <div
    class="group-item"
    :class="{
      'group-item--active': isSelectedGroup && !this.isEditTitle,
      'group-item--loading': isLoading,
    }"
    v-if="group"
  >
    <template v-if="!isLoading">
      <div class="group-item__info">
        <!--    Title     -->
        <template v-if="!isEditTitle">
          <span class="group-item__title">
            {{ group.title }}
          </span>

          <span class="group-item__title-button" @click.stop="toggleEditTitle">
            <svg width="17" height="17">
              <use xlink:href="@/assets/icons.svg#edit"></use>
            </svg>
          </span>
        </template>

        <template v-else>
          <input
            type="text"
            class="group-item__title"
            v-model="editTitleValue"
            ref="titleInput"
            @click.stop
            @keyup.enter="saveTitle"
          />

          <span class="group-item__title-button" @click.stop="toggleEditTitle">
            <svg width="12" height="12">
              <use xlink:href="@/assets/icons.svg#cross"></use>
            </svg>
          </span>

          <span class="group-item__title-button" @click.stop="saveTitle">
            <svg width="20" height="20">
              <use xlink:href="@/assets/icons.svg#check"></use>
            </svg>
          </span>
        </template>

        <!--    Categories count     -->
        <span class="group-item__stat" v-if="group.categories"> {{ group.categories.length || 0 }} categories </span>

        <span class="group-item__controls">
          <span class="group-item__control" @click.stop="$emit('erase')" title="Unbind all categories from group">
            <svg width="16" height="16">
              <use xlink:href="@/assets/icons.svg#erase"></use>
            </svg>
          </span>
          <span class="group-item__control" @click.stop="toggleRemoveModal" title="Remove group">
            <svg width="16" height="16">
              <use xlink:href="@/assets/icons.svg#remove"></use>
            </svg>
          </span>
        </span>
      </div>

      <app-group-labels :labels="group.categories" :group-name="group.title" @remove-label="removeCategory" />

      <app-modal-confirm
        :description="`Group ${group.title} will be deleted.`"
        v-if="isRemoveModalOpen"
        @confirm="remove"
        @cancel="toggleRemoveModal"
      />
    </template>
    <app-loader v-else />
  </div>
</template>

<script>
import AppGroupLabels from '@/modules/groups/components/GroupLabels.vue';
import AppModalConfirm from '@/components/ModalConfirm.vue';
import AppLoader from '@/components/Loader.vue';

import { mapGetters, mapMutations } from 'vuex';

import formatDate from '@/js/functions/date';

export default {
  name: 'AppGroupItem',
  components: {
    AppLoader,
    AppGroupLabels,
    AppModalConfirm,
  },
  props: {
    group: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    editTitleValue: '',
    isEditTitle: false,
    isLoading: false,
    isRemoveModalOpen: false,
  }),
  computed: {
    ...mapGetters(['getSelectedGroup']),
    isSelectedGroup() {
      return this.getSelectedGroup && this.getSelectedGroup.guid === this.group.guid;
    },
  },
  methods: {
    ...mapMutations(['setSelectedGroup']),
    formatDate,
    removeCategory(categoryGuid) {
      const data = {
        ...this.group,
        categories: this.group.categories.filter(item => item.guid !== categoryGuid),
      };

      this.$emit('remove-category', data);
    },
    remove() {
      this.isLoading = true;

      if (this.isSelectedGroup) {
        this.setSelectedGroup(this.group);
      }

      this.$emit('remove', this.group.guid);
    },
    saveTitle() {
      const updatedGroup = {
        ...this.group,
        title: this.editTitleValue,
      };

      this.$emit('title-save', updatedGroup);
      this.toggleEditTitle();
    },
    toggleCategories() {
      this.shouldShowCategories = !this.shouldShowCategories;
      this.shouldShowTags = false;
    },
    toggleEditTitle() {
      this.isEditTitle = !this.isEditTitle;

      if (this.isEditTitle) {
        this.editTitleValue = this.group.title;

        this.$nextTick(() => {
          this.$refs.titleInput.focus();
        });

        return;
      }

      this.editTitleValue = '';
    },
    toggleRemoveModal() {
      this.isRemoveModalOpen = !this.isRemoveModalOpen;
    },
    toggleTags() {
      this.shouldShowTags = !this.shouldShowTags;
      this.shouldShowCategories = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/transitions.scss';
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';

.group-item {
  min-height: 35px;
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  cursor: pointer;

  &:nth-child(2n) {
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: #efefef;
  }
}

.group-item--loading {
  pointer-events: none;
}

.group-item:is(.group-item--active) {
  background-color: $themeColor;
}

.group-item .loader--wrapper {
  margin: 0 !important;
  padding: 20px 0;
}

.group-item__info {
  display: flex;
  align-items: center;
  height: 35px;
  padding-inline: 20px;
  gap: 10px;
}

.group-item__title {
  font-weight: bold;
  font-size: 16px;

  .group-item--active & {
    color: #fff;
  }

  &:is(input) {
    border: none !important;
    background-color: inherit;
    width: fit-content;

    &:focus {
      outline: none;
    }
  }
}

.group-item__stat {
  color: #777;

  .group-item--active & {
    color: #fff;
  }
}

.group-item__controls {
  margin-left: auto;
}

.group-item__control {
  display: none;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;

  .group-item--active & {
    display: inline-flex;
  }

  .group-item--active & > svg {
    fill: #fff;
  }

  .group-item:hover & {
    display: inline-flex;
  }

  &:hover {
    background-color: rgba(crimson, 0.1);
  }

  & > svg {
    fill: crimson;
  }
}

.group-item__title-button {
  opacity: 0.5;
}

.group-item__title-button:hover {
  opacity: 1;
}
</style>
