<template>
  <div class="overlay" @click.self.stop="close">
    <div class="modal" @click.stop>
      <div class="modal__header">
        {{ text }}
      </div>
      <div class="modal__controls">
        <span class="modal__control modal__control--green" @click.stop="close"> {{ okay }} </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppModalError',
  props: {
    text: {
      type: String,
      default: 'Success',
    },
    okay: {
      type: String,
      default: 'OK',
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/modals.scss';

.overlay {
  @include overlay;
}

.modal {
  @include modal;
}

.modal__header {
  @include modal__header;
  text-align: center;
  margin-bottom: 15px;
}

.modal__controls {
  @include modal__controls;
  justify-content: center;
}

.modal__control {
  @include modal__control;
}
</style>
