import APIService from '@/js/api/api-service';

export default class ModelsService {
  // Поиск моделей
  static searchByName(query) {
    const url = `model`;

    let params = `?_filter= title ~ "(?i)${query}"`;

    return APIService.get(url + params)();
  }
}
