<template>
  <section class="group-selection-control">
    <!--    Union     -->
    <span
      class="group-selection-control__button group-selection-control__button--rotate-180"
      :class="{
        'group-selection-control__button--disabled': isCompoundButtonsDisabled,
      }"
      title="Add selected labels to pictures"
      @click="$emit('union')"
    >
      <svg>
        <use xlink:href="@/assets/icons.svg#arrow-right"></use>
      </svg>
    </span>

    <!--    &lt;!&ndash;    Separator     &ndash;&gt;-->
    <!--    <span class="group-selection-control__separator" />-->

    <!--    &lt;!&ndash;    Clear category     &ndash;&gt;-->
    <!--    <span-->
    <!--      class="group-selection-control__button"-->
    <!--      :class="{-->
    <!--        'group-selection-control__button&#45;&#45;disabled': isEraseButtonEnabled,-->
    <!--      }"-->
    <!--      title="Erase group"-->
    <!--      @click="$emit('erase')"-->
    <!--    >-->
    <!--      <svg>-->
    <!--        <use xlink:href="@/assets/icons.svg#erase"></use>-->
    <!--      </svg>-->
    <!--    </span>-->

    <!--    Separator     -->
    <span class="group-selection-control__separator" />

    <!--    Remove category     -->
    <span
      class="group-selection-control__button"
      :class="{
        'group-selection-control__button--disabled': isRemoveButtonDisabled,
      }"
      title="Remove category"
      @click="$emit('remove')"
    >
      <svg>
        <use xlink:href="@/assets/icons.svg#remove"></use>
      </svg>
    </span>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'AppGroupSelectionControl',
  computed: {
    ...mapGetters(['getSelectedGroup', 'getGroupSelectedCategories', 'getGroupCategories']),
    isCompoundButtonsDisabled() {
      return !(this.getSelectedGroup && this.getGroupSelectedCategories?.length);
    },
    isEraseButtonEnabled() {
      return !this.getSelectedGroup;
    },
    isRemoveButtonDisabled() {
      return !this.getGroupSelectedCategories.length;
    },
  },
  methods: {
    ...mapMutations(['setGroupSelectedCategories']),
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/selection-control.scss';

.group-selection-control {
  @include selection-control;
}

.group-selection-control__button {
  @include selection-control__button;
}

.group-selection-control__separator {
  @include selection-control__separator;
}
</style>
