<template>
  <div class="table-head">
    <div class="table-head__cell table-head__cell--search no-sort">
      {{ count ? `Found ${count} galleries` : 'No galleries found' }}

      <app-galleries-search
        :previous-search="previousSearch"
        @set-category="setCategory"
        @set-channel="setChannel"
        @set-search="startSearch"
      />

      <div class="table-head__labels labels" v-if="category || channel">
        <div class="labels__label" v-if="category" @click="$emit('set-category', '')">
          <span class="labels__title">
            {{ category }}
          </span>

          <svg>
            <use xlink:href="@/assets/icons.svg#cross"></use>
          </svg>
        </div>

        <div class="labels__label" v-if="channel" @click="$emit('set-channel', '')">
          <span class="labels__title">
            {{ channel }}
          </span>

          <svg>
            <use xlink:href="@/assets/icons.svg#cross"></use>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="table-head__cell table-head__cell--filter table-head__cell--centered"
      @click.stop="toggleNicheList"
      :class="{
        'has-filter': niche.length,
      }"
    >
      Niche

      <div class="table-filter" v-if="isNicheOpen" v-click-outside="toggleNicheList">
        <span
          class="table-filter__item"
          :class="{
            'table-filter__item--active': !niche.length,
            'table-filter__item--disabled': !niche.length,
          }"
          @click.stop="$emit('set-niche', '')"
        >
          All
        </span>
        <span
          class="table-filter__item"
          :class="{
            'table-filter__item--active': isActiveNiche('straight'),
          }"
          @click.stop="$emit('set-niche', 'straight')"
        >
          Straight
        </span>
        <span
          class="table-filter__item"
          :class="{
            'table-filter__item--active': isActiveNiche('gay'),
          }"
          @click.stop="$emit('set-niche', 'gay')"
        >
          Gay
        </span>
        <span
          class="table-filter__item"
          :class="{
            'table-filter__item--active': isActiveNiche('shemale'),
          }"
          @click.stop="$emit('set-niche', 'shemale')"
        >
          Shemale
        </span>
      </div>
    </div>

    <div class="table-head__cell table-head__cell--centered no-sort">Channel</div>

    <div
      class="table-head__cell table-head__cell--filter table-head__cell--centered"
      :class="{
        'has-filter': status.length,
      }"
      @click="toggleStatusList"
    >
      Status

      <div class="table-filter" v-if="isFilterOpen" v-click-outside="toggleStatusList">
        <span
          class="table-filter__item"
          :class="{
            'table-filter__item--active': !status.length,
            'table-filter__item--disabled': !status.length,
          }"
          @click.stop="$emit('toggle-status', [])"
        >
          All
        </span>
        <span
          class="table-filter__item"
          :class="{
            'table-filter__item--active': isActiveFilter('NEW'),
          }"
          @click.stop="toggleStatus('NEW')"
          v-if="!isReviewer"
        >
          {{ classNames['NEW'] }}
        </span>
        <span
          class="table-filter__item table-filter__item--blue"
          :class="{
            'table-filter__item--active': isActiveFilter('MODERATED'),
          }"
          @click.stop="toggleStatus('MODERATED')"
        >
          {{ classNames['MODERATED'] }}
        </span>
        <span
          class="table-filter__item table-filter__item--green"
          :class="{
            'table-filter__item--active': isActiveFilter('DONE'),
          }"
          @click.stop="toggleStatus('DONE')"
        >
          {{ classNames['DONE'] }}
        </span>
      </div>
    </div>
    <div
      class="table-head__cell"
      :class="{
        'has-sort': isCurrentSort('created'),
        down: sort.direction < 0,
      }"
      @click="setSort('created')"
    >
      Created
    </div>
    <div
      class="table-head__cell"
      :class="{
        'has-sort': isCurrentSort('updated'),
        down: sort.direction < 0,
      }"
      @click="setSort('updated')"
    >
      Last update
    </div>
    <div class="no-sort" />
  </div>
</template>

<script>
import AppGalleriesSearch from '@/modules/galleries-list/components/GalleriesSearch.vue';

import { mapGetters } from 'vuex';

import GalleryStatusNames from '@/js/etc/gallery-status-names';

export default {
  name: 'AppGalleriesHead',
  components: {
    AppGalleriesSearch,
  },
  props: {
    category: {
      default: '',
      type: String,
    },
    channel: {
      default: '',
      type: String,
    },
    count: {
      default: 0,
      type: Number,
    },
    niche: {
      default: '',
      type: String,
    },
    previousSearch: {
      default: '',
      type: String,
    },
    sort: {
      default: () => ({
        direction: 1,
        field: '',
        type: Object,
      }),
    },
    status: {
      default: () => [],
      type: Array,
    },
  },
  data: () => ({
    classNames: GalleryStatusNames,
    isFilterOpen: false,
    isNicheOpen: false,
    isSearchOpen: false,
  }),
  computed: {
    ...mapGetters(['isReviewer']),
  },
  methods: {
    isActiveFilter(filter) {
      return this.status.find(item => item === filter);
    },
    isActiveNiche(niche) {
      return this.niche === niche;
    },
    isCurrentSort(field) {
      return this.sort.field === field;
    },
    setCategory(category) {
      this.$emit('set-category', category.title);
    },
    setChannel(channel) {
      this.$emit('set-channel', channel.title);
    },
    setSort(field) {
      const direction = this.isCurrentSort(field) ? this.sort.direction * -1 : this.sort.direction;
      this.$emit('set-sort', { field, direction });
    },
    startSearch(search) {
      this.$emit('search', search);
    },
    toggleStatus(status) {
      const isEnabled = this.status.find(item => item === status);
      let _status = isEnabled ? this.status.filter(item => item !== status) : [...this.status, status];

      if (_status.length === 3) _status = [];

      this.$emit('toggle-status', _status);
    },
    toggleStatusList() {
      this.isFilterOpen = !this.isFilterOpen;
    },
    toggleNicheList() {
      this.isNicheOpen = !this.isNicheOpen;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../styles/colors';
@import '../../../styles/mixins';

.table-head {
  @include row;
  @include row-sizes;

  background-color: #fff;
  border-bottom: 1px solid #ccc;
  border-radius: 8px 8px 0 0;

  &:only-child {
    border-radius: 8px;
    border-bottom: none;
  }
}

.table-head__cell {
  @include head-cell;
}

.table-head__cell--filter::after {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1 !important;
  background-image: url('@/assets/icons.svg#filter') !important;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
  width: 15px !important;
  height: 15px !important;
  border-radius: 3px;
}

.table-head__cell--filter:is(.has-filter)::after {
  background-color: $themeColor;
  background-image: url('@/assets/icons.svg#filter-white') !important;
}

.table-filter {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 35px;
  padding: 3px;
  border-radius: 5px;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0 0 5px 2px rgba(#222, 0.3);
  gap: 3px;
  cursor: default;
}

.table-filter__item {
  //font-weight: normal;
  font-size: 13px;
  padding: 5px 10px;
  color: #222;
  border-radius: 3px;
  cursor: pointer;

  @include transition;

  &:hover {
    background-color: rgba(#222, 0.1);
  }

  &--disabled {
    pointer-events: none;
    cursor: default;
  }

  &--active {
    color: #fff;
    background-color: #aaa;

    &:hover {
      background-color: rgba(#aaa, 0.5);
    }
  }

  &--green {
    color: forestgreen;

    &:is(.table-filter__item--active) {
      background-color: forestgreen;
      color: #fff;

      &:hover {
        background-color: rgba(forestgreen, 0.5);
      }
    }

    &:hover {
      background-color: rgba(forestgreen, 0.1);
    }
  }

  &--red {
    color: crimson;

    &:is(.table-filter__item--active) {
      background-color: crimson;
      color: #fff;

      &:hover {
        background-color: rgba(crimson, 0.5);
      }
    }

    &:hover {
      background-color: rgba(crimson, 0.1);
    }
  }

  &--orange {
    color: orange;

    &:is(.table-filter__item--active) {
      background-color: orange;
      color: #000;

      &:hover {
        background-color: rgba(orange, 0.5);
      }
    }

    &:hover {
      background-color: rgba(orange, 0.1);
    }
  }

  &--blue {
    color: dodgerblue;

    &:is(.table-filter__item--active) {
      background-color: dodgerblue;
      color: #fff;

      &:hover {
        background-color: rgba(dodgerblue, 0.5);
      }
    }

    &:hover {
      background-color: rgba(dodgerblue, 0.1);
    }
  }
}

.table-head__search-switch {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  height: 25px;
  border-radius: 3px;
  padding: 5px;
  color: #222 !important;
  font-size: 13px;
  font-weight: 200;
  cursor: pointer;

  svg {
    fill: #222;
    max-height: 100%;
    width: 13px;
    margin-right: 4px;
  }
}

.table-head__search-switch--active {
  background-color: #fff;

  svg {
    fill: $themeColor;
  }
}

.table-head__dropdown {
  @include dropdown;

  top: 35px;
  left: 60px;

  &::before {
    content: '';
    position: fixed;
    inset: 0;
    z-index: -1;
  }

  & input {
    border-radius: 3px;
    border: 1px solid #ccc;
    padding: 3px 5px;

    &:focus {
      border-color: #222;
      outline: none;
    }
  }
}

.table-head__search-button {
  @include button;
}

.table-head__category {
  display: inline-flex;
  margin-left: 15px;
}

.table-head__current {
  @include button;
  display: inline-flex;
  width: fit-content;
  font-weight: normal;
  gap: 5px;

  & > svg {
    width: 10px;
    height: 10px;
    fill: #eee;
  }
}

.table-head__info {
  font-size: 0.8rem;
  font-weight: 300;
  color: #777;
  margin-left: 30px;
}

.labels {
  display: flex;
  align-items: center;
  gap: 5px;
}

.labels__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  color: #eee;
  background-color: $themeColor;
  padding: 5px 8px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: normal;

  & > svg {
    width: 9px;
    height: 9px;
    fill: #eee;
  }

  &:hover {
    background-color: $themeColorHover;
  }
}
</style>
