<template>
  <div class="add-label" v-if="true" ref="comp">
    <div class="add-label__switcher add-label__switcher--contrast" @click.stop="toggle">{{ header }}</div>

    <div class="add-label__form" v-if="isShow" ref="form" v-click-outside="toggle" @click.stop>
      <div class="add-label__search">
        <label>
          <input type="text" v-model="title" :placeholder="placeholder" @keyup="onSearch" ref="input" />
          <span class="add-label__clear" @click="clear">
            <svg width="12" height="12">
              <use xlink:href="@/assets/icons.svg#cross"></use>
            </svg>
          </span>
        </label>

        <div class="add-label__suggests" v-if="shouldRenderSuggests || isLoading">
          <template v-if="!isLoading">
            <span class="add-label__suggests-title" v-html="getSuggestsTitle" />
            <ul class="add-label__suggests-list">
              <li
                class="add-label__suggests-item"
                v-for="(suggest, i) in filteredSuggests"
                :key="i"
                @click="setSuggest(suggest, type)"
              >
                {{ suggest.title }}
              </li>
            </ul>
          </template>
          <app-loader v-else />
        </div>
      </div>
    </div>
  </div>
  <app-loader v-else />
</template>

<script>
import AppLoader from '@/components/Loader.vue';

import CategoriesService from '@/js/api/categories-service';
import ModelsService from '@/js/api/models-service';

import { mapGetters } from 'vuex';

export default {
  name: 'AppAddLabel',
  components: {
    AppLoader,
  },
  props: {
    header: {
      type: String,
      default: '+',
    },
    group: {
      type: String,
      default: '',
    },
    niche: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'categories',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isLoading: false,
    isShow: false,
    selectedGroup: '',
    suggests: [],
    timeout: null,
    title: '',
  }),
  computed: {
    ...mapGetters(['getGalleryInfo']),
    filteredSuggests() {
      if (this.type !== 'categories' || !this.niche) {
        return this.suggests;
      }

      return this.suggests.filter(suggest => {
        return suggest.orientation?.title === this.niche;
      });
    },
    getSuggestsTitle() {
      return `Items matching <em>${this.title}</em>`;
    },
    shouldRenderSuggests() {
      return this.title.length >= 3 && this.suggests.length;
    },
  },
  methods: {
    async searchCategories() {
      const onQueryChangedTimeoutSub = search => {
        return async () => {
          if (search.length < 3) return;
          this.isLoading = true;

          const res = await CategoriesService.searchCategories(search)
            .catch(console.warn)
            .finally(() => {
              this.isLoading = false;
            });

          if (res.categories) {
            this.suggests = this.group
              ? res.categories.filter(category =>
                  category.series?.find(item => item.toLowerCase() === this.group.toLowerCase())
                )
              : res.categories;
          }
        };
      };

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(onQueryChangedTimeoutSub(this.title), 500);
    },
    async searchModels() {
      this.isLoading = true;

      const res = await ModelsService.searchByName(this.title)
        .catch(console.warn)
        .finally(() => {
          this.isLoading = false;
        });

      if (res.models) {
        this.suggests = res.models;
      }
    },
    clear() {
      if (!this.title) this.toggle();

      this.title = '';
      this.suggests = [];
    },
    setSuggest(item, type) {
      this.$emit('set-suggest', { item, type });
      this.isShow = false;
    },
    toggle() {
      this.isShow = !this.isShow;
      this.setDropdownPosition();
      this.title = '';
      this.suggests = [];

      this.$nextTick(() => {
        this.isShow && this.$refs.input.focus();
      });
    },
    onSearch() {
      if (this.title.length >= 3) {
        this.type === 'categories' ? this.searchCategories() : this.searchModels();
      } else {
        this.suggests = [];
      }
    },
    setDropdownPosition() {
      if (this.isShow) {
        this.$nextTick(() => {
          const formWidth = this.$refs.form.offsetWidth;
          const compOffset = this.$refs.comp.offsetLeft;
          const translate = formWidth - compOffset;

          if (translate > 0) {
            this.$refs.form.setAttribute('style', `transform: translateX(${translate}px)`);
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';
@import '@/styles/transitions.scss';

.add-label {
  position: relative;
}

.add-label__switcher {
  @include group__label;
  height: 27px;
  line-height: 27px;
}

.add-label__form {
  @include dropdown;

  right: 0;
  top: calc(100% + 5px);
}

.add-label input {
  @include input;
  width: 200px;
}

.add-label__suggests {
  @include dropdown;
  top: 100%;
  width: 200px;
  padding: 5px;
  flex-direction: column;
}

.add-label__suggests-title {
  padding: 5px 7px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.add-label__suggests-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  max-height: 300px;
  overflow: auto;

  @include hideScrollbar;
}

.add-label__suggests-item {
  display: inline-flex;
  padding: 5px 10px;
  cursor: pointer;
  height: 30px;

  &:hover {
    background-color: rgba($themeColor, 0.1);
  }
}

.add-label__save {
  @include button;
}

.add-label__clear {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;

  &:hover svg {
    fill: #222;
  }

  & svg {
    fill: #777;
  }
}

@include list;
@include fade;
</style>
