<template>
  <div class="galleries-search">
    <input
      class="galleries-search__input"
      type="text"
      :placeholder="'Search galleries'"
      v-model="search"
      @keyup="onQueryChanged"
      @keydown.enter="setSearch"
      @paste="onQueryChanged"
      @focus="touch"
    />

    <span class="galleries-search__button" @click="setSearch">
      <svg>
        <use xlink:href="@/assets/icons.svg#search"></use>
      </svg>
    </span>

    <span class="galleries-search__clear" @click="onClearClick" v-if="search || previousSearch">
      <svg>
        <use xlink:href="@/assets/icons.svg#cross"></use>
      </svg>
    </span>

    <div class="galleries-search__suggests suggests" v-if="shouldShowSuggests">
      <app-loader v-if="isLoading" />

      <template v-else>
        <!--      Search by title      -->
        <div class="suggests__group suggests__group--interactive" @click="setSearch">
          <span class="suggests__title">Search galleries by title...</span>
        </div>

        <!--      Categories      -->
        <div class="suggests__group">
          <span class="suggests__title">Categories</span>
          <template v-if="categories.length">
            <ul class="suggests__list">
              <li
                class="suggests__item"
                v-for="category in categories"
                :key="category.guid"
                @click="setCategory(category)"
              >
                {{ category.title }}
              </li>
            </ul>
          </template>
          <span class="suggests__info" v-else>No categories found</span>
        </div>

        <!--      Channels      -->
        <div class="suggests__group">
          <span class="suggests__title">Channels</span>

          <template v-if="channels.length">
            <ul class="suggests__list">
              <li class="suggests__item" v-for="channel in channels" :key="channel.guid" @click="setChannel(channel)">
                {{ channel.title }}
              </li>
            </ul>
          </template>

          <span class="suggests__info" v-else>No channels found</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import AppLoader from '@/components/Loader.vue';

import CategoriesService from '@/js/api/categories-service';
import ChannelsService from '@/js/api/channels-service';

export default {
  name: 'AppGalleriesSearch',
  components: {
    AppLoader,
  },
  props: {
    previousSearch: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    categories: [],
    channels: [],
    isLoading: false,
    isTouched: false,
    search: '',
    timeout: null,
  }),
  computed: {
    shouldShowSuggests() {
      return this.search.length >= 3 && this.isTouched;
    },
  },
  methods: {
    async onQueryChanged() {
      const onQueryChangedTimeoutSub = search => {
        return async () => {
          if (search.length < 3) return;
          this.isLoading = true;

          await Promise.all([
            CategoriesService.searchCategories(search).then(res => {
              this.categories = res.categories || [];
            }),
            ChannelsService.search(search).then(res => {
              this.channels = res.channels || [];
            }),
          ]).finally(() => {
            this.isLoading = false;
          });
        };
      };

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(onQueryChangedTimeoutSub(this.search), 500);
    },
    async touch() {
      if (this.isTouched) return;
      this.isTouched = true;
      await this.onQueryChanged();
    },
    onAction() {
      this.categories = [];
      this.channels = [];
      this.isLoading = false;
      this.isTouched = false;
    },
    onClearClick() {
      if (this.search) {
        this.search = '';
      } else if (this.previousSearch) {
        this.setSearch();
      }
    },
    setCategory(category) {
      this.$emit('set-category', category);
      this.onAction();
    },
    setChannel(channel) {
      this.$emit('set-channel', channel);
      this.onAction();
    },
    setSearch() {
      this.$emit('set-search', this.search);
      this.onAction();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.search = this.previousSearch;
    });
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.galleries-search {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  font-weight: normal;
}

.galleries-search__input {
  @include input;
  height: 40px;
  width: 500px;

  @media (max-width: 1580px) {
    width: 300px;
  }

  @media (max-width: 1400px) {
    width: 250px;
  }
}

.galleries-search__button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;

  &:hover {
    background-color: #f4f4f4;
  }

  & > svg {
    width: 20px;
    height: 20px;
    fill: #555;
  }
}

.galleries-search__clear {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  cursor: pointer;

  @include transition;

  & > svg {
    width: 10px;
    height: 10px;
    fill: #777;
  }

  &:hover > svg {
    fill: #222;
  }
}

.galleries-search__suggests {
  position: absolute;
  top: calc(100% + 3px);
  z-index: 1;
  width: 100%;
}

.suggests {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 3px 1px rgba(#222, 0.1);
}

.suggests__group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}

.suggests__group--interactive {
  cursor: pointer;
}

.suggests__group--interactive:hover {
  background-color: #f5f5f5;
}

.suggests__group--interactive:hover .suggests__title {
  color: #555;
}

.suggests__title {
  font-weight: bold;
}

.suggests__list {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  margin: 0;
  padding: 0;
}

.suggests__item {
  @include group__label;
}

.suggests__info {
  font-size: 12px;
  color: #777;
}
</style>
