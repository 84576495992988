<template>
  <section class="gallery-selection-control">
    <!--    Union     -->
    <span
      class="gallery-selection-control__button gallery-selection-control__button--rotate-180"
      :class="{
        'gallery-selection-control__button--disabled': isMergeButtonsEnabled,
      }"
      title="Add selected labels to pictures"
      @click="add"
    >
      <svg>
        <use xlink:href="@/assets/icons.svg#arrow-right"></use>
      </svg>
    </span>

    <!--    Subtract     -->
    <!--    <span-->
    <!--      class="gallery-selection-control__button"-->
    <!--      :class="{-->
    <!--        'gallery-selection-control__button&#45;&#45;disabled': isMergeButtonsEnabled,-->
    <!--      }"-->
    <!--      title="Remove selected labels from pictures"-->
    <!--      @click="remove"-->
    <!--    >-->
    <!--      <svg>-->
    <!--        <use xlink:href="@/assets/icons.svg#arrow-right"></use>-->
    <!--      </svg>-->
    <!--    </span>-->

    <!--    Separator     -->
    <span class="gallery-selection-control__separator" />

    <!--    Select all pics     -->
    <span
      class="gallery-selection-control__button"
      :class="{
        'gallery-selection-control__button--disabled': isSelectPicturesDisabled,
      }"
      title="Select all pictures"
      @click="selectAllPictures"
    >
      <svg>
        <use xlink:href="@/assets/icons.svg#marker"></use>
      </svg>
    </span>

    <!--    Unselect all pics     -->
    <span
      class="gallery-selection-control__button gallery-selection-control__button--unselect-all"
      :class="{
        'gallery-selection-control__button--disabled': isUnselectPicturesDisabled,
      }"
      title="Unselect all pictures"
      @click="cleanGallerySelectedPictures"
    >
      <svg>
        <use xlink:href="@/assets/icons.svg#marker"></use>
      </svg>
    </span>

    <!--    Separator     -->
    <span class="gallery-selection-control__separator" />

    <!--    Clear selected pics     -->
    <span
      class="gallery-selection-control__button"
      :class="{
        'gallery-selection-control__button--disabled': isErasePicturesButtonDisabled,
      }"
      title="Erase selected pictures info"
      @click="eraseSelectedPictures"
    >
      <svg>
        <use xlink:href="@/assets/icons.svg#erase"></use>
      </svg>
    </span>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'AppGallerySelectionControl',
  props: {
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'getGalleryInfo',
      'getGalleryPictures',
      'getGallerySelectedCategories',
      'getGallerySelectedModels',
      'getGallerySelectedPictures',
      'getGalleryFilterCategories',
      'getGalleryFilterModels',
    ]),
    isErasePicturesButtonDisabled() {
      return !this.getGallerySelectedPictures.length;
    },
    isMergeButtonsEnabled() {
      return (
        (!this.getGallerySelectedCategories.length && !this.getGallerySelectedModels.length) ||
        !this.getGallerySelectedPictures.length
      );
    },
    isSelectPicturesDisabled() {
      return this.getGalleryPictures?.length === this.getGallerySelectedPictures?.length;
    },
    isUnselectPicturesDisabled() {
      return !this.getGallerySelectedPictures?.length;
    },
  },
  methods: {
    ...mapMutations([
      'cleanGallerySelectedCategories',
      'cleanGallerySelectedModels',
      'cleanGallerySelectedPictures',
      'setGalleryHasEdited',
      'setGallerySelectedCategories',
      'setGallerySelectedModels',
      'setGallerySelectedPictures',
      'subtractGalleryLabelsFromPictures',
      'unionGalleryLabelsToPictures',
      'updateSinglePictureInfo',
    ]),
    add() {
      this.unionGalleryLabelsToPictures();
      this.setGalleryHasEdited(true);
      this.clean();
    },
    clean() {
      this.cleanGallerySelectedPictures();
      this.cleanGallerySelectedModels();
      this.cleanGallerySelectedCategories();
    },
    eraseSelectedPictures() {
      // Логика метода: при наличии активных фильтров картинок - будет удалять модели\категории, которые являются активными фильтрами
      // при отсутствии активных фильтров - очистит выбранные изображения от моделей, тегов, категорий.

      // Функция для удаления категорий\моделей с выбранных фотографий, если эти категории\модели являются активным фильтром
      const _eraseActiveFiltersFromPicture = picture => {
        const categories = picture.categories.filter(category => {
          return !this.getGalleryFilterCategories.some(_category => _category.guid === category.guid);
        });

        const models = picture.models.filter(model => {
          return !this.getGalleryFilterModels.some(_model => _model.guid === model.guid);
        });

        const erased = {
          ...picture,
          categories,
          models,
          tags: [],
        };

        this.updateSinglePictureInfo(erased);
      };

      if (this.getGalleryFilterCategories.length || this.getGalleryFilterModels.length) {
        this.getGallerySelectedPictures.forEach(_eraseActiveFiltersFromPicture);
        this.clean();
        return;
      }

      // Очистка фотографий от всех тегов, моделей
      this.getGallerySelectedPictures.forEach(picture => {
        const erased = {
          ...picture,
          categories: [],
          models: [],
          tags: [],
        };

        this.updateSinglePictureInfo(erased);
      });

      this.clean();
    },
    remove() {
      this.subtractGalleryLabelsFromPictures();
      this.setGalleryHasEdited(true);
      this.clean();
    },
    save() {
      this.$emit('save');
    },
    selectAllPictures() {
      this.cleanGallerySelectedPictures();
      this.getGalleryPictures.forEach(this.setGallerySelectedPictures);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/selection-control.scss';

.gallery-selection-control {
  @include selection-control;
}

.gallery-selection-control__button {
  @include selection-control__button;
}

.gallery-selection-control__separator {
  @include selection-control__separator;
}
</style>
