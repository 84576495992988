<template>
  <div class="niches">
    <span
      class="niches__niche"
      :class="{
        'niches__niche--selected': isSelectedNiche('straight'),
      }"
      @click="$emit('set-niche', 'straight')"
    >
      Straight
    </span>
    <span
      class="niches__niche"
      :class="{
        'niches__niche--selected': isSelectedNiche('gay'),
      }"
      @click="$emit('set-niche', 'gay')"
    >
      Gay
    </span>
    <span
      class="niches__niche"
      :class="{
        'niches__niche--selected': isSelectedNiche('shemale'),
      }"
      @click="$emit('set-niche', 'shemale')"
    >
      Shemale
    </span>
  </div>
</template>

<script>
export default {
  name: 'AppNiches',
  props: {
    niche: {
      type: String,
      default: 'straight',
    },
  },
  methods: {
    isSelectedNiche(niche) {
      return this.niche === niche;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';

.niches {
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  width: fit-content;
}

.niches__niche {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  user-select: none;
  cursor: pointer;
  height: 40px;

  @include transition;

  &--selected {
    background-color: $themeColor;
    color: #fff;
    pointer-events: none;
  }

  &:not([class$='--selected']):hover {
    background-color: #eee;
  }

  & + & {
    border-left: 1px solid #ccc;
  }
}
</style>
