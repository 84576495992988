<template>
  <app-layout-overview>
    <template v-slot:left>
      <app-image-picker :gallery-info="getGalleryInfo" />
    </template>

    <template v-slot:panel>
      <app-gallery-selection-control />
    </template>

    <template v-slot:right>
      <app-label-picker @need-update-gallery="fetchGalleryInfo" @save-pictures="save" />
      <app-gallery-commit v-if="canSave" :is-saving="isSaving" :is-commit="isCommit" @save="onSave" />
    </template>

    <template v-slot:modals>
      <app-confirm
        description="You have unsaved changes. This data will be lost."
        confirm-text="Leave page"
        v-if="showDialog"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
      <app-modal-success v-if="showSuccessModal" @close="onCloseModal('showSuccessModal')" text="Successfully saved!" />
      <app-modal-error v-if="showErrorModal" @close="onCloseModal('showErrorModal')" />

      <app-gallery-navigation
        v-if="getPreviousGallery()"
        :to="{
          name: 'galleryOverview',
          query: { ...$route.query, index: getIndex() + 1 },
          params: { guid: getPreviousGallery() },
        }"
      />
      <app-gallery-navigation v-if="getRightArrowRoute()" orientation="right" :to="getRightArrowRoute()" />
    </template>
  </app-layout-overview>
</template>

<script>
import AppLayoutOverview from '@/components/LayoutOverview.vue';
import AppLabelPicker from '@/modules/gallery/components/LabelPicker.vue';
import AppImagePicker from '@/modules/gallery/components/ImagePicker.vue';
import AppGalleryCommit from '@/modules/gallery/components/GalleryCommit.vue';
import AppGallerySelectionControl from '@/modules/gallery/components/GallerySelectionControl.vue';
import AppGalleryNavigation from '@/modules/gallery/components/GalleryNavigation.vue';
import AppConfirm from '@/components/ModalConfirm.vue';
import AppModalSuccess from '@/components/ModalSuccess.vue';
import AppModalError from '@/components/ModalSuccess.vue';

import GalleriesService from '@/js/api/galleries-service';
import PicturesService from '@/js/api/pictures-service';
import GalleriesHistory from '@/js/etc/galleries-history';

import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'AppGallery',
  components: {
    AppImagePicker,
    AppLayoutOverview,
    AppLabelPicker,
    AppGallerySelectionControl,
    AppGalleryNavigation,
    AppGalleryCommit,
    AppConfirm,
    AppModalError,
    AppModalSuccess,
  },
  data: () => ({
    guid: '',
    from: '',
    isCommit: false,
    isLoading: false,
    isSaving: false,
    nextGallery: null,
    pictures: [],
    previousGallery: '',
    showDialog: false,
    showErrorModal: false,
    showSuccessModal: false,
    tags: [],
    to: null,
    gallerySelectedPictures: [],
  }),
  computed: {
    ...mapGetters([
      'getGalleryHasEdited',
      'getGalleryInfo',
      'getGalleryPictures',
      'getGallerySelectedModels',
      'getGallerySelectedPictures',
      'getGallerySelectedCategories',
      'getGalleryThumbs',
      'isAdmin',
      'isReviewer',
      'isSupervisor',
    ]),
    canSave() {
      return (
        !this.isReviewer ||
        this.getGalleryInfo.review === 'MODERATED' ||
        (this.getGalleryInfo.review === 'DONE' && this.isReviewer)
      );
    },
  },
  methods: {
    ...mapMutations([
      'cleanGallerySelectedModels',
      'cleanGallerySelectedPictures',
      'cleanGallerySelectedCategories',
      'setGalleryHasEdited',
      'setGalleryInfo',
      'setGalleryPictures',
      'setGalleryThumbs',
      'updateSinglePictureInfo',
    ]),
    async onSave() {
      if (this.getGalleryInfo.review === 'MODERATED' || this.getGalleryInfo.review === 'NEW') {
        await this.save().then(() => {
          this.setStatus();
        });
      }

      if (this.getGalleryInfo.review === 'DONE') {
        await this.save().then(() => {
          this.updateRoute();
        });
      }
    },
    async setStatus() {
      const guid = this.getGalleryInfo.guid;

      let status;
      switch (this.getGalleryInfo.review) {
        case 'NEW':
          status = { review: 'MODERATED' };
          break;
        case 'MODERATED':
          status = { review: 'DONE' };
          break;
      }

      await GalleriesService.setGalleryStatus(guid, status)
        .then(async () => {
          await this.updateRoute();
        })
        .catch(() => {
          this.onError();
        })
        .finally(() => {
          this.isCommit = false;
        });
    },
    async save() {
      if (this.isSaving) return;
      this.isSaving = true;

      const links = this.getGalleryPictures.map(picture => {
        return {
          guid: picture.guid,
          tags: picture.tags,
          models: picture.models,
          categories: picture.categories,
          channels: picture.channels,
        };
      });

      await PicturesService.setInfoMulti({ links })
        .then(() => {
          this.onSuccess();

          this.cleanGallerySelectedCategories();
          this.cleanGallerySelectedPictures();
          this.cleanGallerySelectedModels();

          this.setGalleryHasEdited(false);
        })
        .catch(() => {
          this.onError();
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    async getImages() {
      if (this.isLoading) return;
      this.isLoading = true;

      const requests = [
        await PicturesService.getGalleryPicturesByGalleryGuid(this.guid)().then(res => {
          if (res.pictures) {
            this.setGalleryPictures(res.pictures);
          } else {
            this.setGalleryPictures([]);
          }
        }),
        await PicturesService.getThumbsByGalleryGuid(this.guid)().then(res => {
          if (res.thumbs) {
            this.setGalleryThumbs(res.thumbs);
          } else {
            this.setGalleryThumbs([]);
          }
        }),
      ];

      Promise.all(requests).finally(() => {
        this.isLoading = false;
      });
    },
    async fetchGalleryInfo() {
      if (this.isLoading) return;
      this.isLoading = true;

      const galleryInfo = await GalleriesService.getGalleryByGuid(this.guid).finally(() => {
        this.isLoading = false;
      });

      if (galleryInfo.result) {
        this.setGalleryInfo(galleryInfo.result);
      }
    },
    async fetchNextGallery() {
      const currentStatus = this.getGalleryInfo.review;
      const guid = this.getGalleryInfo.guid;

      const query = this.$route.query;

      const sortField = query.sort?.replace('-', '') || 'created';
      const sortDirection = query.sort ? (this.from?.query?.sort?.charAt(0) === '-' ? 'desc' : 'asc'``) : 'desc';

      const hasFilter = query.category || query.channel || query.niche;
      const alias = hasFilter ? 'g.' : '';

      let payload = {};

      if (hasFilter) {
        payload.filterBy = [];

        if (query.niche) {
          payload.orientations = [query.niche];
          payload.filterBy.push('orientation');
        }

        if (query.channel) {
          payload.channels = [query.channel];
          payload.filterBy.push('channel');
        }

        if (query.category) {
          payload.categories = [query.category];
          payload.filterBy.push('category');
        }
      }

      let filterString = `(${alias}guid != "${guid}") and (review == "${currentStatus}")`;

      await GalleriesService.getAll(
        1,
        1,
        sortField,
        sortDirection,
        filterString
      )(payload).then(res => {
        if (res.galleries) {
          this.nextGallery = res.galleries.at(0);
        }
      });
    },
    async updateRoute() {
      let route;

      if (this.getGalleryInfo.review !== 'DONE' && this.nextGallery) {
        route = { name: 'galleryOverview', query: this.$route.query, params: { guid: this.nextGallery.guid } };
      } else {
        route = { name: 'galleries', query: this.$route.query };
      }

      await this.$router.push(route).then(() => {
        this.$router.go(0);
      });
    },
    getIndex() {
      return +this.$route.query.index || 0;
    },
    getPreviousGallery() {
      return GalleriesHistory.previous(this.getGalleryInfo.guid, this.getIndex() + 1);
    },
    getRightArrowRoute() {
      const index = this.getIndex() - 1;
      let route;

      route = {
        name: 'galleryOverview',
        query: { ...this.$route.query, index },
        params: { guid: GalleriesHistory.previous(this.getGalleryInfo.guid, index) },
      };

      return ~index && route;
    },
    onCancel() {
      this.showDialog = false;
    },
    onCloseModal(prop) {
      clearInterval(this[prop]);
      this[prop] = false;
    },
    onConfirm() {
      this.showDialog = false;
      this.$router.push(this.to);
    },
    onError() {
      if (this.showErrorModal) return;
      this.showErrorModal = setTimeout(() => {
        this.showErrorModal = false;
      }, 2000);
    },
    onSuccess() {
      if (this.showSuccessModal) return;
      this.showSuccessModal = setTimeout(() => {
        this.showSuccessModal = false;
      }, 2000);
    },
  },
  async beforeMount() {
    this.guid = this.$route.params.guid;

    if (!this.getIndex()) {
      GalleriesHistory.add(this.guid);
    }

    await this.fetchGalleryInfo();
    await this.fetchNextGallery();
    await this.getImages();
  },
  beforeUnmount() {
    this.setGalleryInfo({});
    this.setGalleryPictures([]);

    this.cleanGallerySelectedCategories();
    this.cleanGallerySelectedPictures();
    this.cleanGallerySelectedModels();
  },
  beforeRouteLeave(to, from, next) {
    if (this.getGalleryHasEdited) {
      if (this.to) {
        next();
      } else {
        this.to = to;
        this.showDialog = true;
      }
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="scss"></style>
