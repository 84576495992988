const Config = {
  state: () => ({
    config: {},
  }),
  getters: {
    getConfig: state => {
      return function (key) {
        return state.config && state.config[key];
      };
    },
  },
  mutations: {
    setConfig: (state, obj) => (state.config = obj),
  },
};

export default Config;
