<template>
  <section class="layout-overview">
    <div
      class="layout-overview__area layout-overview__area--left"
      :class="{
        'layout-overview__area--no-padding': noPadding,
      }"
    >
      <slot name="left" />
    </div>
    <div class="layout-overview__inter">
      <slot name="panel" />
    </div>
    <div class="layout-overview__area layout-overview__area--right" ref="right">
      <slot name="right" />
    </div>

    <slot name="modals" />
  </section>
</template>

<script>
import { debounce } from 'throttle-debounce';

export default {
  name: 'AppLayoutOverview',
  props: {
    noPadding: {
      type: Boolean,
      default: false,
    },
    showPanel: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setRightAreaHeight() {
      const scroll = window.scrollY;
      let offset;

      if (this.$refs.right?.offsetTop) {
        offset = this.$refs.right.offsetTop;

        if (scroll <= offset - 20) {
          this.$nextTick(() => {
            this.$refs.right.style.height = `calc(100vh - ${offset - scroll + 5}px)`;
          });
        }
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', debounce(30, this.setRightAreaHeight.bind(this)));
    setTimeout(() => {
      this.setRightAreaHeight();
    }, 100);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', debounce(30, this.setRightAreaHeight.bind(this)));
  },
};
</script>

<style scoped lang="scss">
@import '../styles/colors';
@import '@/styles/transitions.scss';

.layout-overview {
  display: flex;
  gap: 10px;
  width: min(100vw, 1440px);

  @media (min-width: 1441px) {
    width: min(100vw, 1920px);
  }
}

.layout-overview__area {
  border-radius: 8px;
  background-color: #eee;
  border: 1px solid #ccc;
  padding: 10px;

  &--no-padding {
    padding: 0;
  }
}

.layout-overview__area--left {
  flex-grow: 1;
}

.layout-overview__area--right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 450px;
  height: calc(100vh - 95px);
  position: sticky;
  top: 20px;
  overflow-y: auto;
  overflow-x: visible;
  flex-shrink: 0;
  padding-bottom: 10px;
  z-index: 2;

  @media (min-width: 1441px) {
    width: 600px;
  }
}

.layout-overview__inter {
  width: 40px;
  height: calc(100vh - 170px);
  position: sticky;
  top: 20px;
  flex-shrink: 0;
  padding-bottom: 80px;
}

@include slide-bottom;
</style>
