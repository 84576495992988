<template>
  <app-loader v-if="isLoading" />
  <div class="group-create-category" v-else>
    <label>
      <span class="group-create-category__niche">
        <svg width="15" height="15">
          <use xlink:href="@/assets/icons.svg#straight" v-if="isCurrentNiche('straight')" />
          <use xlink:href="@/assets/icons.svg#gay" v-if="isCurrentNiche('gay')" />
          <use xlink:href="@/assets/icons.svg#shemale" v-if="isCurrentNiche('shemale')" />
        </svg>
      </span>
      <input type="text" v-model="category" placeholder="Category name" @keyup.enter="create" />
      <span class="group-create-category__clear" v-if="category.length" @click="clear">
        <svg width="10" height="10">
          <use xlink:href="@/assets/icons.svg#cross"></use>
        </svg>
      </span>
    </label>
    <div
      class="group-create-category__button group-create-category__button--green"
      :class="{
        'group-create-category__button--disabled': !category.length,
        'group-create-category__button--grey': !category.length,
      }"
      @click="create"
    >
      Create
    </div>
  </div>
  <div class="error" v-if="errorText">
    {{ errorText }}
  </div>
</template>

<script>
import AppLoader from '@/components/Loader.vue';

import capitalize from '@/js/functions/capitalize';

import CategoriesService from '@/js/api/categories-service';
import { mapGetters } from 'vuex';
export default {
  name: 'AppGroupCreateCategory',
  components: { AppLoader },
  data: () => ({
    category: '',
    errorText: '',
    isLoading: false,
  }),
  computed: {
    ...mapGetters(['getGroupCategories', 'getGroupNiche']),
  },
  emits: ['success'],
  methods: {
    async create() {
      if (this.isExistingCategory()) {
        this.errorText = `Can't create category. Category ${capitalize(this.category)} already exists.`;
        return;
      } else {
        this.errorText = '';
      }

      const payload = { title: capitalize(this.category), url: '', orientation: this.getGroupNiche };

      await CategoriesService.createCategory({ payload })
        .then(() => {
          this.$emit('success');
          this.clear();
        })
        .catch(console.warn)
        .finally(() => {
          this.isLoading = false;
        });
    },
    clear() {
      this.category = '';
    },
    isCurrentNiche(niche) {
      return niche === this.getGroupNiche.title;
    },
    isExistingCategory() {
      return this.getGroupCategories.some(_category => _category.title.toLowerCase() === this.category.toLowerCase());
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.group-create-category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-block: 10px;

  & label {
    width: 100%;
    position: relative;
  }

  & input {
    @include input;
    height: 40px;
    padding-left: 30px;
  }
}

.group-create-category__button {
  @include button;
  font-weight: bold;
  height: 40px;
  width: 70px;
}

.group-create-category__clear {
  @include clear;
}

.group-create-category__niche {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;

  & > svg {
    fill: #777;
  }
}

.error {
  font-weight: bold;
  color: crimson;
  font-size: 12px;
  margin-left: 5px;
}
</style>
