import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import ClickOutsideDirective from '@/js/directives/click-outside';
import FocusDirective from '@/js/directives/focus';

import ConfigStorageService from '@/js/api/config-storage-service';

fetch(process.env.BASE_URL + 'config.json')
  .then(res => res.json())
  .then(config => {
    if (config) {
      ConfigStorageService.store(config);
      store.commit('setConfig', config);

      createApp(App)
        .directive('click-outside', ClickOutsideDirective)
        .directive('focus', FocusDirective)
        .use(router)
        .use(store)
        .mount('#app');
    }
  });
