<template>
  <li
    class="group-tags__label"
    :class="{
      'group-tags__label--inverted': isSelected,
    }"
  >
    <span class="label__input" v-if="isEdit" v-click-outside="close">
      <input @click.stop class="label__title" type="text" v-model="title" v-focus />
      <span class="label__edit" v-if="!isSelected && isEdit" @click.stop="save">
        <svg width="15" height="15">
          <use xlink:href="@/assets/icons.svg#check"></use>
        </svg>
      </span>
    </span>

    <span class="label__title" v-if="!isEdit">
      {{ category.title }}
    </span>
    <span class="label__edit" v-if="!isSelected && !isEdit" @click.stop="open">
      <svg width="17" height="17">
        <use xlink:href="@/assets/icons.svg#edit"></use>
      </svg>
    </span>
    <span class="label__remove" v-if="isSelected && !isEdit && showCrossButton">
      <svg width="10" height="10">
        <use xlink:href="@/assets/icons.svg#cross"></use>
      </svg>
    </span>
  </li>
</template>

<script>
import CategoriesService from '@/js/api/categories-service';
import capitalize from '@/js/functions/capitalize';

export default {
  name: 'AppGroupEditableLabel',
  props: {
    category: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    showCrossButton: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isEdit: false,
    title: '',
  }),
  methods: {
    async save() {
      const formattedTitle = capitalize(this.title.trim());

      if (formattedTitle || formattedTitle === this.category.title) {
        this.close();
      }

      const payload = {
        title: formattedTitle,
        url: this.category.url,
        orientation: this.category.orientation || { title: 'straight' },
      };

      await CategoriesService.update(this.category.guid, { payload })
        .then(() => {
          this.$emit('update-list');
        })
        .finally(this.close);
    },
    open() {
      this.isEdit = true;
      this.title = this.category.title;
    },
    close() {
      this.title = '';
      this.isEdit = false;
    },
  },
};
</script>
