<template>
  <div class="gallery-item">
    <div>
      <!--      <router-link :to="`/gallery/${gallery.guid}`" class="gallery-item__title">-->
      <!--        {{ gallery.title || 'No title' }} {{ $route }}-->
      <!--      </router-link>-->
      <router-link
        :to="{ name: 'galleryOverview', params: { guid: gallery.guid }, query: $route.query }"
        class="gallery-item__title"
      >
        {{ gallery.title || 'No title' }}
      </router-link>
    </div>

    <div class="gallery-item__cell gallery-item__cell--centered">
      <span
        class="gallery-item__icon gallery-item__icon--niche"
        :class="{
          'gallery-item__icon gallery-item__icon--active': isCurrentNiche('gay'),
        }"
        v-if="gallery.orientation?.title === 'gay'"
        @click="$emit('set-niche', 'gay')"
        title="Gay"
      >
        <svg>
          <use xlink:href="@/assets/icons.svg#gay"></use>
        </svg>
      </span>
      <span
        class="gallery-item__icon gallery-item__icon--niche"
        :class="{
          'gallery-item__icon gallery-item__icon--active': isCurrentNiche('shemale'),
        }"
        v-else-if="gallery.orientation?.title === 'shemale'"
        @click="$emit('set-niche', 'shemale')"
        title="Shemale"
      >
        <svg>
          <use xlink:href="@/assets/icons.svg#shemale"></use>
        </svg>
      </span>
      <span
        class="gallery-item__icon gallery-item__icon--niche"
        :class="{
          'gallery-item__icon gallery-item__icon--active': isCurrentNiche('straight'),
        }"
        v-else
        @click="$emit('set-niche', 'straight')"
        title="Straight"
      >
        <svg>
          <use xlink:href="@/assets/icons.svg#straight"></use>
        </svg>
      </span>
    </div>

    <div class="gallery-item__cell">
      <span
        class="gallery-item__filter"
        :class="{
          'gallery-item__filter--active': gallery.channels[0]?.title === currentChannel,
        }"
        @click="$emit('set-channel', gallery.channels[0]?.title)"
      >
        {{ gallery.channels[0]?.title }}
      </span>
    </div>

    <div class="gallery-item__cell gallery-item__cell--centered" @click="$emit('toggle-status', gallery.review)">
      <span class="gallery-item__status" :class="galleryStatusClasses">
        {{ statusNames[gallery.review] || 'NEW' }}
      </span>
    </div>

    <div class="gallery-item__cell gallery-item__cell--no-inline-padding gallery-item__cell--no-block-padding">
      <span class="gallery-item__info" v-if="gallery.created">
        {{ formatDate(gallery.created) }}
      </span>
    </div>

    <div class="gallery-item__cell gallery-item__cell--no-inline-padding gallery-item__cell--no-block-padding">
      <span class="gallery-item__info" v-if="gallery.updated">
        {{ formatDate(gallery.updated) }}
      </span>
    </div>

    <div class="gallery-item__cell gallery-item__cell--no-inline-padding gallery-item__cell--no-block-padding">
      <a :href="gallery.url" title="Open on Pornpics" class="gallery-item__icon" target="_blank">
        <svg>
          <use xlink:href="@/assets/icons.svg#open"></use>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
import GalleryStatusNames from '@/js/etc/gallery-status-names';
import formatDate from '@/js/functions/date';
export default {
  name: 'AppGalleriesItem',
  props: {
    gallery: {
      type: Object,
      required: true,
    },
    currentChannel: {
      type: String,
      default: '',
    },
    niche: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    statusNames: GalleryStatusNames,
  }),
  computed: {
    galleryStatusClasses() {
      return {
        'gallery-item__status--green': this.gallery.review === 'DONE',
        'gallery-item__status--blue': this.gallery.review === 'MODERATED',
      };
    },
  },
  methods: {
    formatDate,
    isCurrentNiche(niche) {
      return this.niche === niche;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../styles/colors';
@import '../../../styles/mixins';

.gallery-item {
  @include row;
  @include table-row;
  @include row-sizes;

  &:last-child {
    border-radius: 0 0 8px 8px;
  }
}

.gallery-item__cell {
  @include table-cell;
}

.gallery-item__cell--centered {
  justify-content: center;
}

.gallery-item__cell--inline-padding {
  padding-inline: 20px;
  padding-block: 0;
}

.gallery-item__cell--no-inline-padding {
  padding-inline: 0;
}

.gallery-item__cell--no-block-padding {
  padding-block: 0;
}

.gallery-item__title {
  font-size: 12px;
  color: #222;
  text-decoration: none;

  &:hover {
    color: $themeColor;
  }

  @include text-overflow-ellipsis;
  @include table-cell;

  width: 100%;
  display: inline-flex;
}

.gallery-item__status {
  padding: 1px 3px;
  font-size: 12px;
  background-color: #aaa;
  border-radius: 3px;
  color: #fff;
  user-select: none;
  cursor: pointer;

  &--green {
    background-color: forestgreen;
  }

  &--red {
    background-color: crimson;
  }

  &--orange {
    background-color: orange;
    color: #000;
  }

  &--blue {
    background-color: dodgerblue;
  }
}

.gallery-item__info {
  @include table-cell;

  color: #777;
}

.gallery-item__filter {
  cursor: pointer;
  color: #777;
  padding: 1px 3px;
  font-size: 12px;
  margin: 0 auto;
  text-align: center;

  &:not([class$='--active']):hover {
    color: $themeColor;
  }

  &--active {
    background-color: $themeColor;
    color: #fff;
    border-radius: 3px;
  }

  &--active:hover {
    background-color: $themeColorHover;
  }
}

.gallery-item__icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  opacity: 0.5;
  cursor: pointer;
  @include transition;

  & > svg {
    width: 25px;
    height: 25px;
  }

  &:hover {
    opacity: 1;
  }
}

.gallery-item__icon--niche {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  height: 26px;
  width: 26px;

  svg {
    fill: #777;
    width: 20px;
    height: 20px;
  }
}

.gallery-item__icon--niche:is(.gallery-item__icon--active) {
  opacity: 1;
  border-radius: 3px;
  background-color: $themeColor;

  &:hover {
    background-color: $themeColorHover;
  }

  svg {
    fill: #eee;
  }
}
</style>
