<template>
  <div
    class="image"
    :class="{
      'image--selection-mode': selected,
      'image--selected': selected,
    }"
    @click="select"
  >
    <span
      class="image__image"
      :class="{
        'image__image--loading': isLoading,
      }"
      :key="image"
      v-if="imageUrl"
    >
      <img :src="imageUrl" :alt="index + 1" @error="e => e.currentTarget.remove()" @load="onLoad" />
    </span>
    <transition name="slide-bottom">
      <app-image-info :categories="image.categories" :models="image.models" v-if="showInfo" @removeItem="removeItem" />
    </transition>
  </div>
</template>

<script>
import AppImageInfo from '@/modules/Image/components/ImageInfo.vue';

import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'AppImage',
  components: {
    AppImageInfo,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
    imageUrl: {
      required: true,
    },
    index: Number,
    showInfo: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: true,
  }),
  computed: {
    ...mapGetters(['getGalleryFilterCategories', 'getGalleryFilterModels']),
  },
  methods: {
    ...mapMutations(['updateSinglePictureInfo', 'setGalleryHasEdited']),
    onLoad() {
      this.isLoading = false;
    },
    removeItem(itemInfo) {
      const { type, index } = itemInfo;

      const payload = {
        guid: this.image.guid,
        [type]: this.image[type]?.filter((item, i) => i !== index) || [],
      };

      this.updateSinglePictureInfo({ ...this.image, ...payload });
      this.setGalleryHasEdited(true);
    },

    select() {
      this.$emit('select', { ...this.image, tags: this.tags });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';
@import '@/styles/transitions.scss';

.image {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 5px;
  user-select: none;
  border: 2px solid transparent;

  @include transition;
  transition-property: border-color, box-shadow;

  &::before {
    content: '';
    position: absolute;
    display: block;
    inset: 0;
    background-color: rgba($themeColor, 0.3);
    z-index: -1;
    opacity: 0;

    @include transition;
  }

  &::after {
    content: '';
    width: 50px;
    height: 80px;
    border-bottom: 10px solid #fff;
    border-right: 10px solid #fff;
    rotate: 45deg;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    z-index: -1;
    opacity: 0;

    @include transition;
  }
}

.image--selected {
  box-shadow: 0 0 5px 3px $themeColor;
  border-color: $themeColor;

  &::before {
    opacity: 1;
    z-index: 2;
  }

  &::after {
    opacity: 1;
    z-index: 1;
  }
}

.image__image {
  display: flex;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  position: relative;

  & > img {
    width: 100%;
    object-fit: contain;
  }
}

.image__image--loading {
  pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    display: block;
    inset: 0;
    backdrop-filter: blur(15px);
    background-color: rgba(#fff, 0.7);
  }

  &::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    border-radius: 50%;
    border: 5px solid transparent;
    border-right-color: $themeColor;
    background-color: rgba(#fff, 0.5);

    animation: 1s ease-out infinite rotate;

    @keyframes rotate {
      0% {
        rotate: 0deg;
      }

      100% {
        rotate: 360deg;
      }
    }
  }
}

@include slide-bottom;
</style>
