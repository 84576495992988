<template>
  <div class="galleries-list">
    <div class="galleries-list__loader" v-if="isLoading">
      <app-loader />
    </div>

    <!--    Heading     -->
    <app-galleries-head
      :category="filterCategory"
      :channel="filterChannel"
      :niche="filterNiche"
      :previous-search="searchTitle"
      :sort="sort"
      :status="status"
      :count="pagination.total"
      @search="onSearch"
      @set-category="onSetCategory"
      @set-channel="onSetChannel"
      @set-niche="onSetNiche"
      @setSort="onSort"
      @toggleStatus="onSetStatus"
    />

    <!--    Body     -->
    <template v-for="(gallery, index) in galleries" :key="index">
      <app-galleries-item
        :gallery="gallery"
        :current-channel="filterChannel"
        :niche="filterNiche"
        @toggle-status="toggleStatus"
        @set-channel="onSetChannel"
        @set-niche="onSetNiche"
      />
    </template>
  </div>

  <!--    Pagination     -->
  <AppPagination :pagination="pagination" @pageSelect="onPageSelect" />
</template>

<script>
import AppGalleriesHead from '@/modules/galleries-list/components/GalleriesHead.vue';
import AppGalleriesItem from '@/modules/galleries-list/components/GalleriesItem.vue';
import AppPagination from '@/components/Pagination.vue';

import GalleriesService from '@/js/api/galleries-service';
import PaginationService from '@/js/api/pagination-service';
import AppLoader from '@/components/Loader.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'AppGalleriesList',
  components: {
    AppLoader,
    AppGalleriesHead,
    AppGalleriesItem,
    AppPagination,
  },
  data: () => ({
    galleries: [],
    filterCategory: '',
    filterChannel: '',
    filterNiche: '',
    isLoading: false,
    pagination: () => ({}),
    searchTitle: '',
    sort: {
      field: 'created',
      direction: -1,
    },
    status: [],
  }),
  computed: {
    ...mapGetters(['isReviewer', 'getUser']),
    hasFilter() {
      return this.filterChannel || this.filterCategory || this.filterNiche;
    },
  },
  methods: {
    async getGalleries(page = this.pagination.page) {
      if (this.isLoading) return;
      this.isLoading = true;

      const direction = this.sort.direction > 0 ? 'asc' : 'desc';

      let payload = {};

      if (this.hasFilter) {
        payload.filterBy = [];

        if (this.filterNiche) {
          payload.orientations = [this.filterNiche];
          payload.filterBy.push('orientation');
        }

        if (this.filterChannel) {
          payload.channels = [this.filterChannel];
          payload.filterBy.push('channel');
        }

        if (this.filterCategory) {
          payload.categories = [this.filterCategory];
          payload.filterBy.push('category');
        }
      }

      const req = await GalleriesService.getAll(
        page,
        this.pagination.perPage,
        this.sort.field,
        direction,
        this.getFilterString()
      )(payload).finally(() => {
        this.isLoading = false;
      });

      if (req.info) {
        this.pagination.total = req.info.total;
      } else {
        this.pagination.total = 0;
      }

      if (req.galleries) {
        this.galleries = [...req.galleries];
      } else {
        this.galleries = [];
      }
    },
    getFilterString() {
      let statusString = '';
      const alias = this.hasFilter ? 'g.' : '';

      if (this.status.length) {
        this.status.forEach(filter => {
          statusString = statusString.length ? `${statusString} or review == "${filter}"` : `review == "${filter}"`;
        });
      }

      if (this.isReviewer) {
        statusString = statusString.length ? `${statusString} or review != "NEW"` : `review != "NEW"`;
      }

      if (this.searchTitle) {
        statusString = statusString.length
          ? `(${statusString}) and ${alias}title ~ "(?i)${this.searchTitle}"`
          : `${alias}title ~ "(?i)${this.searchTitle}"`;
      }

      return statusString;
    },
    onSetCategory(category) {
      this.filterCategory = this.filterCategory === category ? '' : category;
      this.updateParams();
      this.getGalleries();
    },
    onSetChannel(channel) {
      this.filterChannel = this.filterChannel === channel ? '' : channel;
      this.updateParams();
      this.getGalleries();
    },
    onSetNiche(niche) {
      this.filterNiche = this.filterNiche === niche ? '' : niche;
      this.updateParams();
      this.getGalleries();
    },
    onSetStatus(status = this.status) {
      this.status = status;
      this.pagination.page = 1;

      this.updateParams();
      this.getGalleries();
    },
    onPageSelect(page) {
      this.pagination.page = page;
      this.updateParams();
      this.getGalleries();
    },
    onSearch(search) {
      this.pagination.page = 1;
      this.searchTitle = search;
      this.updateParams();
      this.getGalleries();
    },
    onSort(sortObj) {
      this.sort = sortObj;
      this.pagination.page = 1;

      this.updateParams();
      this.getGalleries();
    },
    toggleStatus(status) {
      const isEnabled = this.status.find(item => item === status);
      this.status = isEnabled ? this.status.filter(item => item !== status) : [...this.status, status];

      this.onSetStatus();
    },
    updateParams() {
      const query = {};

      if (this.sort.direction !== -1 && this.sort.field !== 'created') {
        query.sort = `${this.sort.direction > 0} ? '' : '-'${this.sort.field}`;
      } else {
        delete query.sort;
      }

      this.filterChannel ? (query.channel = this.filterChannel) : delete query.channel;
      this.filterCategory ? (query.category = this.filterCategory) : delete query.category;
      this.filterNiche ? (query.niche = this.filterNiche) : delete query.niche;
      this.status.length ? (query.status = this.status.join(',')) : delete query.status;
      this.searchTitle ? (query.search = this.searchTitle) : delete query.search;

      if (this.pagination.page !== 1) {
        query.p = this.pagination.page;
      } else {
        delete query.p;
      }

      this.$router.push({ query });
    },
  },
  beforeMount() {
    const query = this.$route.query || {};

    this.sort.field = query.sort?.replace('-', '') || 'created';
    this.sort.direction = query.sort ? (query.sort.charAt(0) === '-' ? -1 : 1) : -1;
    this.filterChannel = query.channel || '';
    this.filterCategory = query.category || '';
    this.filterNiche = query.niche || '';

    this.status = query.status?.split(',') || [];
    this.searchTitle = query.search || '';

    this.pagination = new PaginationService(35, +query.p || 1);

    if (this.getUser) {
      this.getGalleries();
    }
  },
  beforeRouteUpdate() {
    this.getGalleries();
  },
  watch: {
    getUser: {
      handler(newValue, oldValue) {
        if (newValue?.login !== oldValue?.login) {
          this.getGalleries();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../styles/colors';
@import '../../styles/mixins';

.galleries-list {
  position: relative;
  padding-bottom: 20px;
  overflow: hidden;
}

.galleries-list__loader {
  position: fixed;
  inset: 0;
  backdrop-filter: blur(5px);
  background-color: rgba(#fff, 0.2);
  z-index: 1;
}

.galleries-list__search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  width: fit-content;
  margin-bottom: 10px;

  & > * {
    margin: 0;
  }
}

.galleries-list__search-button {
  @include button;
  width: 47px;
  min-width: 47px;
  height: 47px;
  padding: 0;
  border-radius: 10px;
}
</style>
