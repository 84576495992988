<template>
  <div class="quick-search">
    <label for="quick-search">
      <input
        type="text"
        name="quick-search"
        id="quick-search"
        autocomplete="off"
        v-model="search"
        :placeholder="placeholder"
        @keyup="onType"
        @paste="onType"
      />

      <span class="quick-search__clear" @click="clear" v-if="search.length" title="Clear">
        <svg>
          <use xlink:href="@/assets/icons.svg#cross"></use>
        </svg>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'AppQuickSearch',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    previousSearch: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    search: '',
  }),
  methods: {
    clear() {
      this.search = '';
      this.onType();
    },
    onSave() {
      this.$emit('save');
    },
    onType() {
      this.$emit('search', this.search.toLowerCase());
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.search = this.previousSearch;
    });
  },
};
</script>

<style scoped lang="scss">
@import '../styles/mixins';
@import '../styles/colors';

.quick-search {
  display: flex;
  margin-bottom: 20px;
  width: max(100%, 430px);
  position: relative;

  & label {
    display: inline-block;
    width: max(100%, 430px);
  }

  & input {
    @include input;
  }
}

.quick-search__clear {
  @include clear;
}
</style>
