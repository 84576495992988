<template>
  <div class="gallery-commit">
    <div
      class="gallery-commit__button gallery-commit__button--green"
      :class="{
        'gallery-commit__button--disabled': isSaveButtonDisabled || isSaving,
        'gallery-commit__button--grey': isSaveButtonDisabled || isSaving,
        'gallery-commit__button--purple-border': isSaving,
      }"
      @click="$emit('save')"
      title="Send changes to server"
    >
      <span v-if="isSaving" class="gallery-commit__loader" />
      <span v-else>Save</span>
    </div>

    <!--    <div-->
    <!--      v-if="getGalleryInfo.review !== 'DONE'"-->
    <!--      class="gallery-commit__button gallery-commit__button&#45;&#45;green"-->
    <!--      :class="{-->
    <!--        'gallery-commit__button&#45;&#45;disabled': isCommit,-->
    <!--        'gallery-commit__button&#45;&#45;grey': isCommit,-->
    <!--        'gallery-commit__button&#45;&#45;purple-border': isCommit,-->
    <!--      }"-->
    <!--      @click="$emit('commit')"-->
    <!--      title="Update gallery status"-->
    <!--    >-->
    <!--      <span v-if="isCommit" class="gallery-commit__loader" />-->
    <!--      <span v-else>Approve</span>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppGalleryConfirm',
  props: {
    isCommit: {
      type: Boolean,
      default: false,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['getGalleryHasEdited', 'getGalleryInfo']),
    isSaveButtonDisabled() {
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.gallery-commit {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.gallery-commit__button {
  @include button;
  width: 100%;
  padding: 8px;
  font-weight: bold;
  position: relative;
  cursor: pointer;

  & span {
    pointer-events: none;
  }

  & + &::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 100%;
    background-color: #ccc;
    margin-right: 10px;
    position: absolute;
    left: -12px;
  }

  &--purple-border {
    border-color: $themeColor;
  }
}

.gallery-commit__loader {
  @include loader--primary;
}
</style>
