import CreateSelectionSwitcher from '@/store/functions/array-switch';

const Groups = {
  state: () => ({
    categories: [],
    groupNiche: { title: 'straight' },
    groupSelectedCategories: [],
    groupSelectedTags: [],
    selectedGroup: null,
    tags: [],
  }),
  getters: {
    getGroupCategories: state => state.categories,
    getGroupNiche: state => state.groupNiche,
    getGroupTags: state => state.tags,
    getGroupSelectedCategories: state => state.groupSelectedCategories,
    getGroupSelectedTags: state => state.groupSelectedTags,
    getSelectedGroup: state => state.selectedGroup,
  },
  mutations: {
    addGroupSelectedCategory: CreateSelectionSwitcher('groupSelectedCategories'),
    addGroupSelectedTag: CreateSelectionSwitcher('groupSelectedTags'),
    setGroupCategories: (state, categories) => (state.categories = categories),
    setGroupNiche: (state, nicheTitle) => (state.groupNiche.title = nicheTitle),
    setGroupTags: (state, tags) => (state.tags = tags),
    setGroupSelectedCategories: (state, categories) => (state.groupSelectedCategories = categories),
    setGroupSelectedTags: (state, tags) => (state.groupSelectedTags = tags),
    setSelectedGroup: (state, group) =>
      (state.selectedGroup = state.selectedGroup && state.selectedGroup.guid === group.guid ? null : group),
  },
};

export default Groups;
