<template>
  <div class="group-create">
    <div
      v-if="!isOpen"
      class="group-create__switcher"
      :class="{
        'group-create__switcher--active': isOpen,
      }"
      @click="open"
    >
      {{ isOpen ? 'Cancel' : '+ Create new group' }}
    </div>

    <template v-if="isOpen">
      <app-loader v-if="isLoading" />

      <div
        class="group-create__form"
        :class="{
          'group-create__form--error': error || p_error,
        }"
        v-else
      >
        <input type="text" placeholder="Group title" v-model="group" @keyup.enter="save" />

        <span
          class="group-create__button group-create__button--green"
          :class="{
            'group-create__button--disabled': !group.length,
            'group-create__button--grey': !group.length,
          }"
          @click="save"
        >
          Create
        </span>

        <span class="group-create__button group-create__button--red" @click="open">Cancel</span>

        <span class="group-create__error" v-if="error || p_error">
          {{ error || p_error }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import AppLoader from '@/components/Loader.vue';

export default {
  name: 'AppGroupCreate',
  components: {
    AppLoader,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    niche: {
      type: String,
      default: 'straight',
    },
    p_error: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    group: '',
    error: '',
    isOpen: false,
  }),
  methods: {
    open() {
      this.isOpen = !this.isOpen;
    },
    save() {
      this.error = '';

      if (!this.group?.trim()) {
        this.error = 'Group title is required';
        return;
      }

      const data = {
        title: this.group.trim(),
        orientation: {
          title: this.niche,
        },
      };

      this.$emit('save', data);

      let timeout;
      const sub_watch = () => {
        timeout = setTimeout(() => {
          if (!this.isLoading) {
            if (!this.p_error) {
              this.isOpen = false;
              this.group = '';
              clearTimeout(timeout);
            }
          } else {
            sub_watch();
          }
        }, 50);
      };

      sub_watch();
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';

.group-create {
  display: flex;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
}

.group-create__switcher {
  padding: 8px 15px;
  background-color: $themeColor;
  color: #fff;
  font-weight: bold;
  border-radius: 3px;
  line-height: 22px;
  cursor: pointer;
  width: 100%;

  @include transition;

  &:hover {
    background-color: #9b65fd;
    border-color: #9b65fd;
  }

  &--active {
    background-color: #fff;
    color: $themeColor;

    &:hover {
      background-color: #fff;
      color: #9b65fd;
    }
  }
}

.group-create__form {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding-right: 10px;

  & input {
    height: 40px;
    width: 100%;
    border: none;
    padding-inline: 20px;
    font-size: 16px;
    font-weight: bold;

    &::placeholder {
      font-weight: normal;
      font-size: 14px;
    }
  }

  & input:focus {
    outline: none;
  }

  &--error input,
  &--error input:hover:not(:focus) {
    border-color: crimson;

    &:not(:focus)::placeholder {
      color: crimson;
    }
  }
}

.group-create__button {
  @include button;
  color: #ffffff;
  font-weight: bold;
  padding: 3px;
  user-select: none;
}

.group-create__error {
  color: crimson;
  font-weight: bold;
}
</style>
