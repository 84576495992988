<template>
  <div class="label-picker" v-if="getGalleryInfo">
    <div class="label-picker__head">
      <span
        class="label-picker__status"
        :class="{
          'label-picker__status--blue': getGalleryInfo.review === 'MODERATED',
          'label-picker__status--green': getGalleryInfo.review === 'DONE',
        }"
      >
        {{ getGalleryStatus }}
      </span>
      <a class="label-picker__title" :href="getGalleryInfo.url" v-if="getGalleryInfo.url">
        {{ getGalleryInfo?.title }}
      </a>
      <h1 class="label-picker__title" v-else>
        {{ getGalleryInfo?.title }}
      </h1>
    </div>

    <!--    Labels      -->
    <ul class="label-picker__groups" v-if="getGalleryInfo?.channels?.at(0)">
      <li class="label-picker__group" v-if="canManageItems">
        <ul class="label-picker__list">
          <li class="label-picker__inline-title">Niche:</li>
          <li
            class="label-picker__label"
            :class="{
              'label-picker__label--inverted':
                getGalleryInfo.orientation?.title === 'straight' || !getGalleryInfo.orientation,
              'label-picker__label--disabled':
                getGalleryInfo.orientation?.title === 'straight' || !getGalleryInfo.orientation,
            }"
            @click="setNiche('straight')"
          >
            Straight
          </li>
          <li
            class="label-picker__label"
            :class="{
              'label-picker__label--inverted': getGalleryInfo.orientation?.title === 'gay',
              'label-picker__label--disabled': getGalleryInfo.orientation?.title === 'gay',
            }"
            @click="setNiche('gay')"
          >
            Gay
          </li>
          <li
            class="label-picker__label"
            :class="{
              'label-picker__label--inverted': getGalleryInfo.orientation?.title === 'shemale',
              'label-picker__label--disabled': getGalleryInfo.orientation?.title === 'shemale',
            }"
            @click="setNiche('shemale')"
          >
            Shemale
          </li>
        </ul>
      </li>

      <li class="label-picker__group">
        <div class="label-picker__list">
          <span class="label-picker__inline-title">Channel:</span>
          <router-link
            class="label-picker__channel"
            target="_blank"
            :to="{ path: '/galleries', query: { channel: getGalleryInfo.channels?.at(0).title } }"
          >
            {{ getGalleryInfo.channels?.at(0).title }}
          </router-link>
        </div>
      </li>

      <li class="label-picker__group">
        <ul class="label-picker__list">
          <li class="label-picker__inline-title">Models:</li>
          <li
            class="label-picker__label label"
            :class="{
              'label-picker__label--inverted': isModelSelected(model),
            }"
            :key="index"
            v-for="(model, index) in getGalleryInfo.models"
            @click="selectModel(model)"
          >
            <span class="label__title">
              {{ model.title }}
            </span>
            <span
              class="label__remove"
              @click.stop="onRemoveItem(model.guid, 'models')"
              v-if="canManageItems"
              title="Remove from gallery"
            >
              <svg width="10" height="10">
                <use xlink:href="@/assets/icons.svg#cross"></use>
              </svg>
            </span>
          </li>
          <li>
            <app-add-label
              v-if="canManageItems"
              title="Add model to gallery"
              type="models"
              :placeholder="'Search models'"
              @set-suggest="onSuggestSet"
            />
          </li>
        </ul>
      </li>
      <li
        class="label-picker__group"
        :class="{
          'label-picker__group--unsorted': key === 'Unsorted',
        }"
        v-for="(group, key) in groupedBySeries"
        :key="key"
      >
        <ul class="label-picker__list">
          <li class="label-picker__inline-title">{{ key === '_' ? '#' : key }}:</li>
          <li
            class="label-picker__label"
            :class="{
              'label-picker__label--inverted': isLabelSelected(label),
            }"
            :key="index"
            v-for="(label, index) in group"
            @click="select(label)"
          >
            <span class="label__title">
              {{ label.title }}
            </span>
            <router-link
              class="label__link"
              :to="{ path: '/galleries', query: { category: label.title } }"
              target="_blank"
              @click.stop
            >
              <svg width="17" height="17">
                <use xlink:href="@/assets/icons.svg#diagonal-arrow"></use>
              </svg>
            </router-link>
            <span
              class="label__remove"
              @click.stop="onRemoveItem(label.guid, 'categories')"
              v-if="canManageItems"
              title="Remove from gallery"
            >
              <svg width="10" height="10">
                <use xlink:href="@/assets/icons.svg#cross"></use>
              </svg>
            </span>
          </li>
          <li>
            <app-add-label
              v-if="canManageItems"
              title="Add category to gallery"
              :group="key === 'Unsorted' ? '' : key"
              :placeholder="`Search in group ${key}`"
              :niche="getGalleryInfo.orientation?.title || 'straight'"
              @set-suggest="onSuggestSet"
            />
          </li>
        </ul>
      </li>
    </ul>

    <!--    No models     -->
    <ul class="label-picker__list" v-if="canManageItems && !getGalleryInfo?.models?.length">
      <li class="label-picker__inline-title">Models:</li>
      <li>
        <app-add-label
          title="Add model to gallery"
          type="models"
          :placeholder="'Search models'"
          @set-suggest="onSuggestSet"
        />
      </li>
    </ul>

    <!--    No categories     -->
    <ul class="label-picker__list" v-if="canManageItems">
      <li class="label-picker__inline-title">Add categories:</li>
      <li>
        <app-add-label
          title="Add category to gallery"
          :placeholder="`Search in all categories`"
          :niche="getGalleryInfo.orientation?.title || 'straight'"
          @set-suggest="onSuggestSet"
        />
      </li>
      <li>
        <router-link
          to="/categories?mode=create"
          class="label-picker__label label-picker__label--link label-picker__label--inverted"
          target="_blank"
        >
          Create new category
        </router-link>
      </li>
    </ul>

    <!--    Info tags      -->
    <ul class="label-picker__tags" v-if="getGalleryInfo?.tags?.length">
      <li class="label-picker__tags-title">Tags:</li>
      <li class="label-picker__tag" v-for="tag in getGalleryInfo.tags" :key="tag.guid">
        {{ tag.title }}
      </li>
    </ul>
  </div>
  <app-loader v-else />
  <app-confirm v-if="confirm" description="This category will be deleted from the gallery." :async="confirm" />
</template>

<script>
import AppLoader from '@/components/Loader.vue';
import AppAddLabel from '@/components/AddLabel.vue';
import AppConfirm from '@/components/ModalConfirm.vue';

import { mapGetters, mapMutations } from 'vuex';

import GalleriesService from '@/js/api/galleries-service';
import galleryStatusNames from '@/js/etc/gallery-status-names';
import unique from '@/js/functions/unique';

export default {
  name: 'AppLabelPicker',
  components: {
    AppLoader,
    AppAddLabel,
    AppConfirm,
  },
  emits: ['need-update-gallery', 'save-pictures'],
  data: () => ({
    confirm: undefined,
    filterTitle: '',
    isSettingNiche: false,
    mode: 'select',
  }),
  computed: {
    ...mapGetters([
      'getGalleryInfo',
      'getGalleryPictures',
      'getGallerySelectedCategories',
      'getGallerySelectedModels',
      'getGallerySelectedTags',
      'getGallerySelectedPictures',
      'isReviewer',
    ]),
    canManageItems() {
      return !this.isReviewer;
    },
    getGalleryStatus() {
      return galleryStatusNames[this.getGalleryInfo.review] || 'New';
    },
    groupedBySeries() {
      let groupedLabels = {};
      let result = {};

      this.getGalleryInfo?.categories?.forEach(item => {
        const group = item.series[0] || 'Unsorted';
        groupedLabels[group] ? groupedLabels[group].push(item) : (groupedLabels[group] = [item]);
      });

      groupedLabels = Object.fromEntries(Object.entries(groupedLabels).sort()); // Sorting keys A -> Z
      return { ...result, ...groupedLabels };
    },
  },
  methods: {
    ...mapMutations({
      cleanSelectedPictures: 'cleanGallerySelectedPictures',
      cleanSelectedCategories: 'cleanGallerySelectedCategories',
      cleanSelectedModels: 'cleanGallerySelectedModels',
      select: 'setGallerySelectedCategories',
      selectModel: 'setGallerySelectedModels',
      selectPicture: 'setGallerySelectedPictures',
      subtract: 'subtractGalleryLabelsFromPictures',
      updateGalleryNiche: 'setGalleryNiche',
    }),
    async onRemoveItem(itemGuid, type) {
      if (!this.getGalleryInfo[type]) return;

      const confirm = await new Promise(resolve => {
        this.confirm = resolve;
      }).finally(() => {
        this.confirm = '';
      });

      if (confirm) {
        this.removeItem(itemGuid, type);
      }
    },
    async setNiche(niche) {
      if (this.isSettingNiche) return;
      this.isSettingNiche = true;

      await GalleriesService.setGalleryNiche(this.getGalleryInfo.guid, niche)
        .then(res => {
          if (res) {
            this.updateGalleryNiche(niche);
          }
        })
        .catch(console.warn)
        .finally(() => {
          this.isSettingNiche = false;
        });
    },
    isLabelSelected(label) {
      return this.getGallerySelectedCategories.some(selected => selected.guid === label.guid);
    },
    isModelSelected(model) {
      return this.getGallerySelectedModels.some(selected => selected.guid === model.guid);
    },
    onSearch(query) {
      this.filterTitle = query;
    },
    onSuggestSet(suggest) {
      const { item, type } = suggest;
      let items = unique([...this.getGalleryInfo[type], item]);

      switch (type) {
        case 'models':
          GalleriesService.setGalleryModels(this.getGalleryInfo.guid, { [type]: items }).then(() => {
            this.$emit('need-update-gallery');
          });
          break;
        case 'categories':
          GalleriesService.setGalleryCategories(this.getGalleryInfo.guid, { [type]: items }).then(() => {
            this.$emit('need-update-gallery');
          });
      }
    },
    removeItem(itemGuid, type) {
      const items = [...this.getGalleryInfo[type]].filter(item => item.guid !== itemGuid);

      switch (type) {
        case 'models':
          this.selectModel(this.getGalleryInfo.models.find(item => item.guid === itemGuid));

          this.getGalleryPictures.forEach(picture => {
            if (picture.models.some(model => model.guid === itemGuid)) {
              this.selectPicture(picture);
            }
          });

          this.subtract();
          this.cleanSelectedPictures();
          this.cleanSelectedModels();
          this.$emit('save-pictures');

          GalleriesService.setGalleryModels(this.getGalleryInfo.guid, { [type]: items }).then(() => {
            this.$emit('need-update-gallery');
          });
          break;
        case 'categories':
          this.select(this.getGalleryInfo.categories.find(item => item.guid === itemGuid));

          this.getGalleryPictures.forEach(picture => {
            if (picture.categories.some(category => category.guid === itemGuid)) {
              this.selectPicture(picture);
            }
          });

          this.subtract();
          this.cleanSelectedPictures();
          this.cleanSelectedCategories();
          this.$emit('save-pictures');

          GalleriesService.setGalleryCategories(this.getGalleryInfo.guid, { [type]: items }).then(() => {
            this.$emit('need-update-gallery');
          });
          break;
        default:
          return;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/colors.scss';
@import '@/styles/mixins.scss';

.label-picker {
  height: calc(100% - 45px);
  margin-bottom: 10px;
  overflow: auto;
}

.label-picker__head {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.label-picker__status {
  display: inline-block;
  padding: 3px 6px;
  margin-right: 3px;
  font-weight: bold;
  font-size: 14px;
  background-color: #aaa;
  color: #fff;
  border-radius: 5px;

  &--blue {
    background-color: dodgerblue;
  }

  &--green {
    background-color: forestgreen;
  }
}

.label-picker__title {
  font-size: 16px;
  border-bottom: none !important;
  margin: 0;
  font-weight: bold;
  text-decoration: none;
  color: #222;

  &:is(a):hover {
    color: $themeColor;
  }
}

.label-picker__channel {
  @include group__label;
  text-decoration: none;
}

.label-picker__groups {
  @include groups;
  margin-top: 10px;
}

.label-picker__group {
  @include group;
}

.label-picker__group--unsorted {
  order: 2;
}

.label-picker__inline-title {
  @include group__inline-title;
}

.label-picker__list {
  @include group__list;

  margin-block: 0;

  & > li {
    display: inline-flex;
  }
}

.label-picker__label {
  @include group__label;
  font-size: 14px;

  span:first-child {
    padding-right: 5px;
  }
}

.label-picker__label--link {
  text-decoration: none;
}

.label__remove {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 3px;
  margin-right: -10px;
  width: 20px;
  position: relative;

  & svg {
    fill: #777;
  }

  [class$='inverted'] & svg {
    fill: #fff;
  }

  &:hover svg {
    fill: #222;
  }

  &::before {
    content: '';
    display: inline-block;
    height: 27px;
    width: 1px;
    background-color: #aaa;
    position: absolute;
    left: 0;
  }
}

.label__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 100%;
  position: relative;
  margin-right: -2px;

  [class$='inverted'] & > svg {
    fill: #fff;
  }

  & > svg {
    fill: #777;
  }

  &:hover svg {
    fill: #222;
  }

  &::before {
    content: '';
    display: inline-block;
    height: 27px;
    width: 1px;
    background-color: #aaa;
    position: absolute;
    left: 0;
  }
}

.label-picker__tags {
  @include group__list;
}

.label-picker__tags-title {
  @include group__inline-title;
}

.label-picker__tag {
  display: inline-flex;
  color: #777;
  padding-inline: 1px;
  font-size: 14px;

  &:not(:last-child)::after {
    content: ',';
  }
}
</style>
