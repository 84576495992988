const Auth = {
  state: () => ({
    auth: null,
    user: null,
  }),
  getters: {
    isAdmin: state =>
      (state.user && state.user?.roles?.find(role => role.name === 'admin')) || state.user?.login === 'admin',
    isAuth: state => state.auth,
    isReviewer: state => state.user && state.user?.roles?.find(role => role.name === 'reviewer'),
    isSupervisor: state => state.user && state.user?.roles?.find(role => role.name === 'supervisor'),
    getUser: state => state.user,
  },
  mutations: {
    setAuth: (state, value) => (state.auth = value),
    setUser: (state, user) => (state.user = user),
  },
};

export default Auth;
