import { createRouter, createWebHistory } from 'vue-router';

import AppGallery from '../modules/gallery/Gallery.vue';
import AppGalleriesList from '../modules/galleries-list/GalleriesList.vue';
import AppGroups from '../modules/groups/Groups.vue';

const routes = [
  {
    path: '/',
    redirect: '/galleries',
  },
  {
    path: '/galleries',
    name: 'galleries',
    component: AppGalleriesList,
  },
  {
    path: '/gallery/:guid',
    name: 'galleryOverview',
    component: AppGallery,
  },
  {
    path: '/categories',
    name: 'categories',
    component: AppGroups,
  },
];

const options = {
  history: createWebHistory(process.env.BASE_URL),
  routes,
};

const router = createRouter(options);

export default router;
