<template>
  <div class="group-labels" v-if="labels.length">
    <transition-group tag="div" name="list" class="group-labels__labels">
      <span
        class="group-labels__label"
        :class="{
          'group-labels__label--inverted-mint': isCategory,
        }"
        v-for="label in sortedLabels"
        :key="label.guid"
        @click.stop="remove(label.guid)"
      >
        {{ label.title }}

        <svg>
          <use xlink:href="@/assets/icons.svg#cross"></use>
        </svg>
      </span>
    </transition-group>
  </div>
</template>
<script>
export default {
  name: 'AppGroupLabels',
  props: {
    groupName: {
      type: String,
      required: true,
    },
    isCategory: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    sortedLabels() {
      const _labels = [...this.labels];
      return _labels.sort((prevLabel, nextLabel) => {
        if (prevLabel.title > nextLabel.title) {
          return 1;
        }
        if (prevLabel.title < nextLabel.title) {
          return -1;
        }

        return 0;
      });
    },
  },
  methods: {
    remove(labelGuid) {
      this.$emit('remove-label', labelGuid);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/transitions.scss';

.group-labels__labels {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 20px;
  gap: 2px;
}

.group-labels__label {
  @include group__label;
  display: inline-flex;
  width: fit-content;
  font-size: 12px;
  height: 27px;
  border-width: 1px;

  & > svg {
    width: 8px;
    height: 8px;
    fill: #777;
    margin-left: 3px;
  }

  &:hover > svg {
    fill: #222;
  }

  .group-item--active & {
    border-color: #000;
    background-color: #fff;

    &--inverted-mint {
      border-color: darkcyan;
      color: darkcyan;

      &:hover {
        border-color: mediumturquoise;
        color: mediumturquoise;
      }
    }

    &:hover {
      background-color: #efefef;
    }
  }
}

@include list;
</style>
