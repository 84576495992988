import APIService from '@/js/api/api-service';

export default class GalleriesService {
  // Получить список галлерей
  static getAll = (page, limit, sort, direction, filter, tags, originTag) => {
    let url = `gallery`;
    let params = '';

    if (sort) {
      params = params + (params.length ? '&' : '?') + `_order_by=${sort} ${direction}`;
    }

    if (page) {
      params = params + (params.length ? '&' : '?') + `_offset=${(page - 1) * limit}`;
    }

    if (filter) {
      params = params + (params.length ? '&' : '?') + `_filter=${filter}`;
    }

    if (limit) {
      params = params + (params.length ? '&' : '?') + `_limit=${limit}`;
    }

    if (originTag) {
      params = params + (params.length ? '&' : '?') + `_filter=origin_guid == "${originTag}"`;
    }

    return APIService.post(url + params);
  };

  // Получить информацию о галерее по guid
  static getGalleryByGuid = guid => APIService.get(`gallery/${guid}`)();

  // Получить теги конкретной галлереи
  static getGalleryTags = guid => APIService.get(`gallery/${guid}/tag`)();

  // Установка тегов конкретной галлереи с перезаписью
  static setGalleryTags = (guid, data) => APIService.post(`gallery/${guid}/tag`)(data);

  // Установка категорий конкретной галлереи с перезаписью
  static setGalleryCategories = (guid, data) => APIService.post(`gallery/${guid}/category`)(data);

  // Установка моделей конкретной галлереи с перезаписью
  static setGalleryModels = (guid, data) => APIService.post(`gallery/${guid}/model`)(data);

  // Установка ниши галереи
  static setGalleryNiche = (guid = '', title = '') =>
    APIService.post(`gallery/${guid}/orientation`)({ guid, orientation: { title } });

  // Установка статуса модерации галлереи
  static setGalleryStatus = (guid = '', status = { review: 'NEW' }) =>
    APIService.post(`gallery/${guid}/review`)(status);
}
