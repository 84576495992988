<template>
  <div class="pagination" v-if="pagination.allPages() > 1">
    <div
      class="pagination__button pagination__button--back"
      :class="{
        'pagination__button--grey': !isBackButtonEnabled,
        'pagination__button--disabled': !isBackButtonEnabled,
        'pagination__button--inverted': isBackButtonEnabled,
      }"
      @click="select(pagination.page - 1)"
    >
      <svg>
        <use xlink:href="@/assets/icons.svg#arrow"></use>
      </svg>
    </div>

    <template v-if="pagination.allPages() <= 5">
      <div
        class="pagination__button pagination__button--inverted pagination__button--inverted"
        :class="{
          'pagination__button--active': n === pagination.page,
          'pagination__button--disabled': n === pagination.page,
        }"
        v-for="n in pagination.allPages()"
        :key="n"
        @click="select(n)"
      >
        {{ n }}
      </div>
    </template>

    <!--    start list   -->
    <template v-if="isStartList">
      <div
        class="pagination__button pagination__button--inverted"
        :class="{
          'pagination__button--active': n === pagination.page,
          'pagination__button--disabled': n === pagination.page,
        }"
        v-for="n in 5"
        :key="n"
        @click="select(n)"
      >
        {{ n }}
      </div>
      <span> ... </span>
      <div
        class="pagination__button pagination__button--inverted"
        :key="pagination.allPages()"
        @click="select(pagination.allPages())"
      >
        {{ pagination.allPages() }}
      </div>
    </template>

    <!--    middle list   -->
    <template v-if="isMiddleList">
      <div class="pagination__button pagination__button--inverted" :key="1" @click="select(1)">
        {{ 1 }}
      </div>
      <span> ... </span>
      <div
        class="pagination__button pagination__button--inverted"
        v-for="n in 2"
        :key="pagination.page - 3 + n"
        @click="select(pagination.page - 3 + n)"
      >
        {{ pagination.page - 3 + n }}
      </div>
      <div class="pagination__button pagination__button--active pagination__button--disabled">
        {{ pagination.page }}
      </div>
      <div
        class="pagination__button pagination__button--inverted"
        v-for="n in 2"
        :key="pagination.page + n"
        @click="select(pagination.page + n)"
      >
        {{ pagination.page + n }}
      </div>
      <span> ... </span>
      <div
        class="pagination__button pagination__button--inverted"
        :key="pagination.allPages()"
        @click="select(pagination.allPages())"
      >
        {{ pagination.allPages() }}
      </div>
    </template>

    <!--    end list   -->
    <template v-if="isEndList">
      <div class="pagination__button pagination__button--inverted" :key="1" @click="select(1)">
        {{ 1 }}
      </div>
      <span> ... </span>

      <div
        class="pagination__button pagination__button--inverted"
        :class="{
          'pagination__button--active': pagination.page - 5 + n === pagination.page,
          'pagination__button--disabled': pagination.page - 5 + n === pagination.page,
        }"
        v-for="n in 5"
        :key="pagination.page - 5 + n"
        @click="select(pagination.page - 5 + n)"
      >
        {{ pagination.page - 5 + n }}
      </div>
    </template>

    <div
      class="pagination__button pagination__button--next"
      :class="{
        'pagination__button--grey': isNextButtonDisabled,
        'pagination__button--disabled': isNextButtonDisabled,
        'pagination__button--inverted': !isNextButtonDisabled,
      }"
      @click="select(pagination.page + 1)"
    >
      <svg>
        <use xlink:href="@/assets/icons.svg#arrow"></use>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppPagination',
  props: {
    pagination: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isNextButtonDisabled() {
      return this.pagination.isLastPage();
    },
    isBackButtonEnabled() {
      return this.pagination.page !== 1;
    },
    isStartList() {
      return this.pagination.allPages() > 5 && this.pagination.page < 5;
    },
    isMiddleList() {
      return (
        this.pagination.allPages() > 5 &&
        this.pagination.page >= 5 &&
        this.pagination.page < this.pagination.allPages() - 4
      );
    },
    isEndList() {
      return (
        this.pagination.allPages() > 5 &&
        this.pagination.page >= 5 &&
        this.pagination.page >= this.pagination.allPages() - 5
      );
    },
  },
  methods: {
    select(page) {
      this.$emit('page-select', page);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: min-content;
  margin: 10px auto 30px;
}

.pagination__button {
  @include button;
  background-color: #fff;
  width: 45px;
  height: 45px;

  &--active {
    background-color: #fff;
    font-weight: bold;
    color: $themeColor;
  }

  &--back,
  &--next {
    &:is([class$='disabled']) {
      border-color: #eee;
    }

    & svg {
      fill: #fff;
    }
  }
}

.pagination__button--next {
  svg {
    rotate: -90deg;
  }
}

.pagination__button--back {
  svg {
    rotate: 90deg;
  }
}
</style>
