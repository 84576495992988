const ClickOutsideDirective = {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        event.stopPropagation();
        event.preventDefault();

        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent, { capture: true });
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent, { capture: true });
  },
};

export default ClickOutsideDirective;
