<template>
  <nav class="navigation">
    <router-link to="/galleries" class="navigation__link" active-class="navigation__link--active">
      Galleries
    </router-link>

    <router-link
      v-if="isAdmin || isSupervisor"
      to="/categories"
      class="navigation__link"
      active-class="navigation__link--active"
    >
      Categories
    </router-link>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppNavigation',
  computed: {
    ...mapGetters(['isAdmin', 'isSupervisor']),
  },
};
</script>

<style scoped lang="scss">
.navigation {
  display: flex;
  gap: 25px;
}

.navigation__link {
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding: 10px 15px;
  text-align: left;
  transition: 0.15s ease-out;
}

.navigation__link:hover {
  color: #ccc;
}

.navigation__link--active {
  pointer-events: none;
  border-bottom: 3px solid #fff;
}
</style>
