<template>
  <transition name="fade">
    <div class="overlay" @click.self.stop="cancel">
      <div class="modal" @click.stop>
        <div class="modal__header">
          {{ header }}
        </div>
        <div class="modal__text" v-if="description">
          {{ description }}
        </div>
        <div class="modal__controls">
          <span class="modal__control modal__control--inverted" @click.stop="confirm"> {{ confirmText }} </span>
          <span class="modal__control modal__control--inverted" @click.stop="cancel"> Cancel </span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AppModalConfirm',
  emits: ['confirm', 'cancel'],
  props: {
    header: {
      type: String,
      default: 'Are you sure?',
    },
    description: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: 'Confirm',
    },
    async: {
      type: Function,
      default: undefined,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      if (this.async) this.async(true);
    },
    cancel() {
      this.$emit('cancel');
      if (this.async) this.async(false);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/modals.scss';
@import '@/styles/transitions.scss';

.overlay {
  @include overlay;
}

.modal {
  @include modal;
}

.modal__header {
  @include modal__header;
}

.modal__text {
  @include modal__text;
}

.modal__controls {
  @include modal__controls;
}

.modal__control {
  @include modal__control;
}
</style>
