<template>
  <div
    class="deletable-item deletable-item--contrast"
    :class="{
      'deletable-item--inverted': isActive,
      'deletable-item--icon-before': modelIcon,
    }"
    @click="clickHandler"
    title="Click to delete"
  >
    <span>
      <slot />
    </span>

    <span class="deletable-item__close" @click="remove" v-if="showCloseButton">
      <svg>
        <use xlink:href="@/assets/icons.svg#cross"></use>
      </svg>
    </span>
  </div>
</template>

<script>
export default {
  name: 'AppDeletableItem',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    modelIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    remove() {
      this.$emit('remove');
    },
    clickHandler() {
      if (!this.showCloseButton) {
        this.remove();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../styles/colors';
@import '../styles/mixins';

.deletable-item {
  @include button;
  display: inline-flex;
  padding: 3px 5px;
  border-radius: 3px;
  font-size: 13px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 24px;
  line-height: 1px;
  vertical-align: middle;
}

.deletable-item--icon-before span {
  position: relative;
  display: inline-block;
  align-items: center;
  line-height: 20px;
  height: 20px;
  vertical-align: middle;

  &:first-child::before {
    content: '';
    display: inline-block;
    height: 12px;
    width: 12px;
    background-color: #222;
    mask-size: 12px;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-image: url(@/assets/icons.svg#person);
    margin-right: 3px;
    margin-block: auto;
  }
}

.deletable-item--inverted span:first-child::before {
  background-color: #eee !important;
}

.deletable-item__close {
  display: inline-block;

  & > svg {
    width: 10px;
    height: 10px;
    fill: rgba(#222, 0.3);
    @include transition;
  }

  &:hover svg {
    fill: #222;
  }
}

.deletable-item--inverted .deletable-item__close svg {
  fill: #eee;
}
</style>
