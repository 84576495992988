import ConfigStorageService from '@/js/api/config-storage-service';

export default class APIService {
  static getApiEndpoint() {
    return ConfigStorageService.get('apiEndpoint');
  }

  static get(path) {
    return async function () {
      return fetch(`${APIService.getApiEndpoint()}${path}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
        },
      })
        .then(res => res.json())
        .then(data => data)
        .catch(err => err);
    };
  }

  static delete(path) {
    return async function () {
      return fetch(`${APIService.getApiEndpoint()}${path}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
        },
      })
        .then(res => res.json())
        .then(data => data)
        .catch(err => err);
    };
  }

  static post(path) {
    return async function (data) {
      return fetch(`${APIService.getApiEndpoint()}${path}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
        },
        body: JSON.stringify(data),
      })
        .then(res => res.json())
        .then(data => data)
        .catch(err => err);
    };
  }

  static put(path) {
    return async function (data) {
      return fetch(`${APIService.getApiEndpoint()}${path}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
        },
        body: JSON.stringify(data),
      })
        .then(res => res.json())
        .then(data => data)
        .catch(err => err);
    };
  }

  static patch(path) {
    return async function (data) {
      return fetch(`${APIService.getApiEndpoint()}${path}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
        },
        body: JSON.stringify(data),
      })
        .then(res => res.json())
        .then(data => data)
        .catch(err => err);
    };
  }
}
