<template>
  <div class="group-tags">
    <!--    Tabs     -->
    <app-tabs
      :mode="mode"
      @set-mode="onSetMode"
      :options="[
        { title: 'Select', value: 'select' },
        { title: 'Create category', value: 'create' },
      ]"
    />

    <template v-if="mode === 'create'">
      <app-group-create-category @success="onCreateCategory" />
    </template>

    <template v-if="mode === 'select'">
      <!--    Search     -->
      <app-quick-search @search="onSearch" placeholder="Search categories" :previous-search="query" />

      <!--    Search params   -->
      <app-group-tags-controls
        @select-letter="onLetterChange"
        @select-type="onTypeChange"
        :letter="letter"
        :has-group="hasGroup"
      />

      <!--    Selected Labels List     -->
      <div class="group-tags__group" v-if="getGroupSelectedCategories.length">
        <span class="group-tags__title">Selected categories</span>
        <ul class="group-tags__list label">
          <li
            class="group-tags__label group-tags__label--inverted"
            :key="index"
            v-for="(category, index) in getGroupSelectedCategories"
            @click="addGroupSelectedCategory(category)"
          >
            <span class="label__title">
              {{ category.title }}
            </span>
            <span class="label__remove">
              <svg width="10" height="10">
                <use xlink:href="@/assets/icons.svg#cross"></use>
              </svg>
            </span>
          </li>
        </ul>
      </div>

      <!--    Labels List     -->
      <template v-if="!isLoading">
        <ul class="group-tags__groups" v-if="Object.keys(groupedByLetter).length || getGroupCategories.length">
          <li class="group-tags__group" v-for="(group, key) in groupedByLetter" :key="key">
            <ul class="group-tags__list">
              <li class="group-tags__inline-title" :data-count="`(${group.length}):`">
                {{ key === '_' ? '#' : key }}
              </li>
              <app-group-editable-label
                v-for="category in group"
                :show-cross-button="false"
                :category="category"
                :key="category.guid"
                :is-selected="isSelectedCategory(category)"
                @click="addGroupSelectedCategory(category)"
                @update-list="$emit('update-list')"
              />
            </ul>
          </li>
        </ul>
        <span v-if="isNoTagsMessageShow">No categories found.</span>
      </template>
      <app-loader v-else />
    </template>
  </div>
</template>

<script>
import AppGroupCreateCategory from '@/modules/groups/components/GroupCreateCategory.vue';
import AppGroupTagsControls from '@/modules/groups/components/GroupTagsControls.vue';
import AppGroupEditableLabel from '@/modules/groups/components/GroupEditableLabel.vue';
import AppLoader from '@/components/Loader.vue';
import AppQuickSearch from '@/components/QuickSearch.vue';
import AppTabs from '@/components/Tabs.vue';

import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'AppGroupLabelPicker',
  components: {
    AppGroupCreateCategory,
    AppGroupEditableLabel,
    AppGroupTagsControls,
    AppLoader,
    AppQuickSearch,
    AppTabs,
  },
  props: {
    categoriesList: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    categories: true,
    hasGroup: 'ungrouped',
    letter: 'All',
    mode: 'select',
    query: '',
    tags: false,
  }),
  computed: {
    ...mapGetters([
      'getGroupTags',
      'getGroupSelectedTags',
      'getGroupCategories',
      'getGroupSelectedCategories',
      'getGroupNiche',
    ]),
    groupedByLetter() {
      let groupedLabels = {};

      this.filteredItems.forEach(item => {
        const letter = item.title[0]?.match(/\d/) || item.title[0]?.match(/\W/) ? '_' : item.title[0];
        groupedLabels[letter] ? groupedLabels[letter].push(item) : (groupedLabels[letter] = [item]);
      });

      groupedLabels = Object.fromEntries(Object.entries(groupedLabels).sort()); // Sorting keys A -> Z

      return groupedLabels;
    },
    filteredItems() {
      return this.categoriesList
        .filter(item => {
          switch (this.hasGroup) {
            case 'grouped':
              return item.series?.length;
            case 'ungrouped':
              return !item.series.length;
            case 'all':
              return item;
          }
        })
        .filter(item => !this.query || item.title.toLowerCase().includes(this.query.toLowerCase()))
        .filter(item => this.letter === 'All' || item.title[0] === this.letter)
        .filter(item => item?.title?.length)
        .filter(item => item?.orientation?.title === this.getGroupNiche.title);
    },
    isNoTagsMessageShow() {
      return !Object.keys(this.groupedByLetter).length && !this.isLoading;
    },
  },
  methods: {
    ...mapMutations([
      'addGroupSelectedCategory',
      'addGroupSelectedTag',
      'setGroupCategories',
      'setGroupSelectedCategories',
      'setGroupSelectedTags',
      'setGroupTags',
    ]),
    isSelectedCategory(category) {
      return this.getGroupSelectedCategories.some(_category => _category.guid === category.guid);
    },
    onCreateCategory() {
      this.mode = 'select';
      this.$emit('need-update-categories');
    },
    onLetterChange(letter) {
      this.letter = letter;
    },
    onTypeChange(type) {
      this.hasGroup = type;
    },
    onSearch(value) {
      this.query = value;
    },
    onSetMode(mode) {
      this.mode = mode;
    },
  },
  beforeUnmount() {
    this.setGroupCategories([]);
  },
  mounted() {
    if (this.$route.query?.mode === 'create') {
      this.mode = 'create';
    }
  },
  watch: {
    filteredItems: {
      handler(newValue) {
        this.setGroupCategories(newValue);
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/transitions.scss';
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';

.group-tags__groups {
  @include groups;
}

.group-tags__group {
  @include group;

  border-bottom: none;
}

.group-tags__list {
  @include group__list;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;

  .group-tags__title + & {
    margin: 0;
  }
}

.group-tags__title {
  @include group__title;
  display: inline-flex;
  margin-bottom: 5px;
}

.group-tags__inline-title {
  @include group__inline-title;
  align-items: baseline;

  &::after {
    content: attr(data-count);
    color: #777;
    margin-left: 3px;
    font-size: 0.9rem;
    font-weight: normal;
  }
}

::v-deep .group-tags__label {
  @include group__label;
  height: 30px;

  &--hidden {
    display: none;
  }
}

::v-deep .label__title:is(input) {
  background: none;
  font-size: 16px;
  border: none;
  outline: none !important;
  width: 100%;
}

::v-deep .label__remove {
  cursor: pointer;
  padding: 0 3px;
  margin-right: -3px;

  & svg {
    fill: #999;
  }

  &:hover svg {
    fill: #eee;
  }
}

::v-deep .label__edit {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 0 3px;
  margin-right: -3px;

  & svg {
    fill: #999;
    width: 15px;
    height: 15px;
  }

  &:hover svg {
    fill: #222;
  }
}

::v-deep .label__input {
  display: inline-flex;
  align-items: center;
}
</style>
