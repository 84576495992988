<template>
  <!--  Filter params   -->
  <div class="group-search-params">
    <div class="group-search-params__row">
      <!--  Type   -->
      <!--      <label class="group-search-params__input">-->
      <!--        <input type="checkbox" v-model="categories" @change="onSelect('categories')" />-->
      <!--        <span>Categories</span>-->
      <!--      </label>-->
      <!--      <label class="group-search-params__input">-->
      <!--        <input type="checkbox" v-model="tags" @change="onSelect('tags')" />-->
      <!--        <span>Tags</span>-->
      <!--      </label>-->

      <!--  First letter   -->
      <label class="group-search-params__input">
        <span>First letter</span>
        <select name="letter" id="letter" @change="onLetterSelect">
          <option value="All">All</option>
          <option value="A">A</option>
          <option value="B">B</option>
          <option value="C">C</option>
          <option value="D">D</option>
          <option value="E">E</option>
          <option value="F">F</option>
          <option value="G">G</option>
          <option value="H">H</option>
          <option value="I">I</option>
          <option value="K">K</option>
          <option value="L">L</option>
          <option value="M">M</option>
          <option value="N">N</option>
          <option value="O">O</option>
          <option value="P">P</option>
          <option value="Q">Q</option>
          <option value="R">R</option>
          <option value="S">S</option>
          <option value="T">T</option>
          <option value="U">U</option>
          <option value="V">V</option>
          <option value="W">W</option>
          <option value="X">X</option>
          <option value="Y">Y</option>
          <option value="Z">Z</option>
        </select>
      </label>

      <label class="group-search-params__input">
        <span>Has group</span>
        <select name="letter" id="type" @change="onTypeSelect">
          <option value="ungrouped" selected>Ungrouped</option>
          <option value="grouped">Grouped</option>
          <option value="all">All</option>
        </select>
      </label>
    </div>

    <!--  Filter controls   -->
    <!--    <div class="group-search-params__row">-->
    <!--      <div-->
    <!--        class="group-search-params__control group-search-params__control&#45;&#45;green"-->
    <!--        :class="{-->
    <!--          'group-search-params__control&#45;&#45;disabled': isSelectDisable,-->
    <!--          'group-search-params__control&#45;&#45;grey': isSelectDisable,-->
    <!--        }"-->
    <!--        @click="selectAll"-->
    <!--      >-->
    <!--        Select all-->
    <!--      </div>-->
    <!--      <div-->
    <!--        class="group-search-params__control group-search-params__control&#45;&#45;red"-->
    <!--        :class="{-->
    <!--          'group-search-params__control&#45;&#45;disabled': isUnselectDisable,-->
    <!--          'group-search-params__control&#45;&#45;grey': isUnselectDisable,-->
    <!--        }"-->
    <!--        @click="unselectAll"-->
    <!--      >-->
    <!--        Unselect all-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppGroupTagsControls',
  props: {
    hasGroup: {
      type: String,
      required: true,
    },
    letter: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    categories: true,
    tags: false,
  }),
  computed: {
    ...mapGetters(['getGroupTags', 'getGroupSelectedTags']),
    // isSelectDisable() {
    //   return !this.getGroupTags.length || this.getGroupSelectedTags.length === this.getGroupTags.length;
    // },
    // isUnselectDisable() {
    //   return !this.getGroupSelectedTags.length;
    // },
  },
  methods: {
    onLetterSelect(event) {
      this.$emit('select-letter', event.currentTarget.value);
    },
    onTypeSelect(event) {
      this.$emit('select-type', event.currentTarget.value);
    },
    // selectAll() {
    //   this.$emit('select-all');
    // },
    // unselectAll() {
    //   this.$emit('unselect-all');
    // },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.group-search-params {
  margin-bottom: 20px;
}

.group-search-params {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.group-search-params__row {
  display: flex;
  gap: 20px;
  width: 100%;
}

.group-search-params__input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  & > :focus {
    outline: none;
  }

  & > input {
    display: none;
  }

  & > input + span::before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    border: 1px solid #222;
    background-color: #fff;
  }

  & > input:checked + span::after {
    content: '';
    display: block;
    width: 19px;
    height: 9px;
    border: 3px solid forestgreen;
    border-top: none;
    border-right: none;
    rotate: -45deg;
    z-index: 1;
    position: absolute;
  }

  & > span {
    display: inline-flex;
    gap: 5px;
    font-weight: bold;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  & > select {
    border-radius: 5px;
    padding: 5px 10px;
  }
}

.group-search-params__control {
  @include button;
  color: #fff;
  font-weight: bold;
  padding: 8px 16px;
}
</style>
