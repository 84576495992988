import APIService from '@/js/api/api-service';

export default class GroupsService {
  // Добавление тегов и категорий к выбранным группам
  static addInfo = data => APIService.put(`series/link`)(data);

  // Создание группы
  static create = data => APIService.put(`series`)(data);

  // Удаление группы
  static delete = guid => APIService.delete(`series/${guid}`)();

  // Получить инфо группы по guid
  static getInfo = guid => APIService.get(`series/${guid}`)();

  // Получить список групп
  static getList(filter, limit) {
    let url = `series`;
    let params = '?_order_by=title';

    if (limit) {
      params = params + (params.length ? '&' : '?') + `_limit=${limit}`;
    }

    if (filter) {
      params = params + (params.length ? '&' : '?') + `_filter=${filter}`;
    }

    return APIService.get(url + params)();
  }

  // Перезаписать группы и категории выбранных групп новыми данными
  static setInfo = data => APIService.post(`series/link`)(data);

  // Обновить информацию о группе по guid
  static updateInfo = (guid, data) => APIService.patch(`series/${guid}`)(data);
}
