export default class GalleriesHistory {
  static add(guid) {
    let history = GalleriesHistory.get();

    history = [guid, ...history.filter(item => item !== guid)];

    sessionStorage.setItem('galleries-history', JSON.stringify(history));
  }

  static previous(guid, index = 1) {
    let history = GalleriesHistory.get();
    if (!history.length || !history.at(index)) return;

    let result = history.at(index);

    return result === guid ? GalleriesHistory.previous(guid, ++index) : result;
  }

  static get() {
    const saved = sessionStorage.getItem('galleries-history');

    return saved ? JSON.parse(saved) : [];
  }

  static remove(guid) {
    let history = GalleriesHistory.get();

    sessionStorage.setItem('galleries-history', JSON.stringify(history.filter(item => item !== guid)));
  }
}
