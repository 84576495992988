export default class PaginationService {
  constructor(perPage = 30, initPage = 1) {
    this.total = 0;
    this.perPage = perPage;
    this.page = initPage;
  }

  allPages() {
    return Math.ceil(this.total / this.perPage);
  }

  isLastPage() {
    return this.page === this.allPages();
  }
}
