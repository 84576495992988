<template>
  <section class="groups-list">
    <app-group-create @save="onCreateGroup" />
    <app-group-item
      v-for="group in groups"
      :key="group.guid"
      :group="group"
      @click="setSelectedGroup(group)"
      @erase="$emit('erase', group)"
      @title-save="onTitleSave"
      @remove="onRemove"
      @remove-category="onRemoveCategory"
    />
  </section>
</template>

<script>
import AppGroupItem from '@/modules/groups/components/GroupItem.vue';
import AppGroupCreate from '@/modules/groups/components/GroupCreate.vue';

import { mapMutations } from 'vuex';

export default {
  name: 'AppGroupsList',
  components: {
    AppGroupItem,
    AppGroupCreate,
  },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapMutations(['setSelectedGroup']),
    onCreateGroup(group) {
      this.$emit('create-group', group);
    },
    onRemove(groupGuid) {
      this.$emit('remove-group', groupGuid);
    },
    onRemoveCategory(updatedGroup) {
      this.$emit('remove-category', updatedGroup);
    },
    onTitleSave(group) {
      this.$emit('title-save', group);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';

.groups-list {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.groups-list__head {
  @include table-head;
}

.groups-list__th {
  @include table-head-cell;
}
</style>
