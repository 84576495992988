<template>
  <div class="image-info" v-if="hasInfo" @click.stop>
    <div class="image-info__content">
      <!--      Tags       -->
      <div class="image-info__block">
        <transition-group tag="ul" class="image-info__list" name="list">
          <li class="image-info__item image-info__item--model" v-for="(item, index) in models" :key="item.guid">
            <app-deletable-item
              :model-icon="true"
              :is-active="isCurrentLabelFilter(item, 'getGalleryFilterModels')"
              :show-close-button="true"
              @remove="removeItem(index, 'models')"
            >
              {{ item.title }}
            </app-deletable-item>
          </li>
          <li class="image-info__item" v-for="(item, index) in categories" :key="item.guid">
            <app-deletable-item
              @remove="removeItem(index, 'categories')"
              :is-active="isCurrentTagFilter(item)"
              :show-close-button="true"
            >
              {{ item.title }}
            </app-deletable-item>
          </li>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import AppDeletableItem from '@/components/DeletableItem.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'ImageInfo',
  components: {
    AppDeletableItem,
  },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    models: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isLoading: true,
    tagLoadingIndex: null,
  }),
  computed: {
    ...mapGetters(['getGalleryFilterCategories', 'getGalleryFilterModels']),
    hasInfo() {
      return this.categories?.length || this.models?.length;
    },
  },
  methods: {
    isCurrentLabelFilter(labelValue, labelName) {
      return this[labelName].some(_label => _label.guid === labelValue.guid);
    },
    isCurrentTagFilter(tag) {
      return this.getGalleryFilterCategories.some(_tag => _tag.guid === tag.guid);
    },
    removeItem(index, type) {
      this.$emit('remove-item', { index, type });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';
@import '@/styles/transitions.scss';

.image-info {
  display: block;
  position: absolute;
  width: 100%;
  height: min-content;
  bottom: 0;
  z-index: 1;
  border-radius: 3px 3px 0 0;

  @include transition;
}

.image-info__content {
}

.image-info__block {
  display: flex;
  flex-direction: column;
  padding: 3px;
  gap: 10px;
}

.image-info__block-title {
  font-weight: bold;
  font-size: 15px;
}

.image-info__list {
  display: block;
  flex-wrap: wrap;
  gap: 2px;
  list-style-type: none;
  padding: 0;
  word-spacing: -100%;
  margin: 0;
}

.image-info__item {
  display: inline-block;
  width: fit-content;
  margin: 1px;
  gap: 3px;

  &:nth-child(even) {
    float: left;
    clear: none;
  }
}

@include list;
</style>
