<template>
  <header class="header" v-if="isAuth">
    <app-navigation />

    <div class="header__button" @click="logout">Logout</div>
  </header>
</template>

<script>
import AppNavigation from '@/components/Navigation.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'AppHeader',
  components: {
    AppNavigation,
  },
  computed: {
    ...mapGetters(['isAuth']),
  },
  methods: {
    logout() {
      this.$emit('logout');
    },
  },
};
</script>

<style scoped lang="scss">
@import '../styles/colors';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  height: 70px;
  width: 100%;
  margin: 0 auto 20px;
  color: #eee;
  font-weight: bold;
  z-index: 2;

  &::after {
    content: '';
    display: block;
    width: 100vw;
    height: 70px;
    background-color: $themeColor;
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
  }
}

.header__title {
  margin: 0;
  font-size: 25px;
  user-select: none;
}

.header__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  border-radius: 10px;
  background-color: rgba(#fff, 0.2);
  cursor: pointer;
  transition: 0.3s ease-out;
  user-select: none;
}

.header__button:hover {
  background-color: rgba(#fff, 0.35);
}
</style>
