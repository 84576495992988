import APIService from '@/js/api/api-service';

export default class PicturesService {
  // Получить список картинок низкого качества
  static getThumbsByGalleryGuid = (galleryGuid, page, limit, sort, direction) => {
    let url = `thumb`;
    let params = `?_filter=gallery_guid == "${galleryGuid}"`;

    if (sort) {
      params = params + (params.length ? '&' : '?') + `_order_by=${sort} ${direction}`;
    }

    if (page) {
      params = params + (params.length ? '&' : '?') + `_offset=${(page - 1) * limit}`;
    }

    if (limit) {
      params = params + (params.length ? '&' : '?') + `_limit=${limit}`;
    }

    return APIService.get(url + params);
  };

  // Получить список картинок нормального качества
  static getGalleryPicturesByGalleryGuid = (galleryGuid, page, limit, sort, direction) => {
    let url = `picture`;
    let params = `?_filter=gallery_guid == "${galleryGuid}"`;

    if (sort) {
      params = params + (params.length ? '&' : '?') + `_order_by=${sort} ${direction}`;
    }

    if (page) {
      params = params + (params.length ? '&' : '?') + `_offset=${(page - 1) * limit}`;
    }

    if (limit) {
      params = params + (params.length ? '&' : '?') + `_limit=${limit}`;
    }

    return APIService.get(url + params);
  };

  // Получить список тегов изображения
  static getPictureTags = pictureGuid => APIService.get(`picture/${pictureGuid}/tag`)();

  // Получить список моделей изображения
  static getPictureModels = pictureGuid => APIService.get(`picture/${pictureGuid}/model`)();

  // Установить список тегов изображения с перезаписью
  static setTags = (pictureGuid, data) => APIService.post(`picture/${pictureGuid}/tag`)(data);

  // Перезаписать информацию о изображении (теги, модели, категории, каналы)
  static setInfoMulti = APIService.post(`pictures/link`);

  // Добавить информацию о изображении (теги, модели, категории, каналы)
  static addInfoMulti = APIService.put(`pictures/link`);
}
