import APIService from '@/js/api/api-service';

export default class CategoriesService {
  // Получить список всех категорий
  static getAllCategories = (page, limit, sort = 'title', direction = 'asc') => {
    let url = `category`;
    let params = '';

    if (sort) {
      params = params + (params.length ? '&' : '?') + `_order_by=${sort} ${direction}`;
    }

    if (page) {
      params = params + (params.length ? '&' : '?') + `_offset=${(page - 1) * limit}`;
    }

    if (limit) {
      params = params + (params.length ? '&' : '?') + `_limit=${limit}`;
    }

    return APIService.post(url + params)();
  };

  // Поиск по имени категории и (или) первой букве
  static searchCategories(query, excludeGroups = false, firstLetter) {
    const url = `category`;

    let params = `?_filter= title ~ "(?i)${query}"`;

    if (firstLetter) {
      params += ` and title ~ "(?i)^${firstLetter}.*"`;
    }

    return APIService.post(url + params)({ bySeries: excludeGroups });
  }

  // Создать новую категорию
  static createCategory = payload => APIService.put('category')(payload);

  // Удалить категорию
  static remove = guid => APIService.delete(`category/${guid}`)();

  // Обновить информацию о категории
  static update = (guid, payload) => APIService.patch(`category/${guid}`)(payload);
}
