<template>
  <app-loader v-if="isLoading && !groups.length" />

  <template v-else>
    <div class="groups">
      <div class="groups__controls">
        <app-niches :niche="getGroupNiche.title" @set-niche="setNiche" />
        <div class="group__controls-search">
          <app-quick-search @search="onFilterType" placeholder="Search groups" />
        </div>
        <span>
          {{ filterInfoString }}
        </span>
      </div>

      <app-layout-overview :no-padding="true">
        <template v-slot:left>
          <app-groups-list
            v-if="filteredGroups.length"
            :groups="filteredGroups"
            @create-group="onSave"
            @erase="erase"
            @title-save="updateGroup"
            @remove-group="removeGroup"
            @remove-category="onCategoryDelete"
          />
          <span class="groups__no-groups" v-else>No groups found</span>
        </template>

        <template v-slot:panel>
          <app-group-selection-control @union="union" @remove="onRemoveCategory" />
        </template>

        <template v-slot:right>
          <app-group-label-picker
            :categories-list="categoriesList"
            :is-loading="isFetchingCategories"
            @need-update-categories="getAllCategories"
            @update-list="getAllCategories"
          />
        </template>

        <template v-slot:modals>
          <app-confirm
            v-if="confirm"
            description="This categories will be deleted."
            confirm-text="Remove"
            :async="confirm"
          />
        </template>
      </app-layout-overview>
    </div>
  </template>
</template>

<script>
import AppGroupLabelPicker from '@/modules/groups/components/GroupLabelPicker.vue';
import AppGroupSelectionControl from '@/modules/groups/components/GroupSelectionControl.vue';
import AppGroupsList from '@/modules/groups/components/GroupsList.vue';
import AppLayoutOverview from '@/components/LayoutOverview.vue';
import AppLoader from '@/components/Loader.vue';
import AppNiches from '@/components/Niches.vue';
import AppQuickSearch from '@/components/QuickSearch.vue';
import AppConfirm from '@/components/ModalConfirm.vue';

import { mapGetters, mapMutations } from 'vuex';

import CategoriesService from '@/js/api/categories-service';
import GroupsService from '@/js/api/groups-service';

export default {
  name: 'AppGroups',
  components: {
    AppGroupLabelPicker,
    AppGroupSelectionControl,
    AppGroupsList,
    AppLayoutOverview,
    AppLoader,
    AppNiches,
    AppQuickSearch,
    AppConfirm,
  },
  data: () => ({
    categoriesList: [],
    confirm: undefined,
    errorCreate: '',
    filterGroups: '',
    groups: [],
    isGroupCreating: false,
    isFetchingCategories: false,
    isLoading: false,
  }),
  computed: {
    ...mapGetters([
      'getGroupCategories',
      'getGroupNiche',
      'getGroupSelectedCategories',
      'getSelectedGroup',
      'isReviewer',
    ]),
    filteredGroups() {
      return this.groups
        .filter(group => group.title.toLowerCase().includes(this.filterGroups))
        .filter(group => group.orientation.title === this.getGroupNiche.title);
    },
    filterInfoString() {
      return `Showing ${this.filteredGroups?.length} of ${
        this.groups?.filter(group => group.orientation.title === this.getGroupNiche.title)?.length
      } groups`;
    },
  },
  methods: {
    ...mapMutations(['setSelectedGroup', 'setGroupSelectedCategories', 'setGroupCategories', 'setGroupNiche']),
    async getAllCategories() {
      this.setGroupCategories([]);
      this.categoriesList = [];
      this.isFetchingCategories = true;

      CategoriesService.getAllCategories().then(res => {
        this.categoriesList = res.categories;
        this.isFetchingCategories = false;
        this.setGroupCategories(res.categories);
      });
    },
    async getGroupList() {
      if (this.isLoading) return;
      this.isLoading = true;

      const res = await GroupsService.getList().finally(() => {
        this.isLoading = false;
      });

      if (res.series.length) {
        this.groups = res.series;
      }
    },
    async onRemoveCategory() {
      const confirm = await new Promise(resolve => {
        this.confirm = resolve;
      }).finally(() => {
        this.confirm = '';
      });

      if (confirm) {
        await Promise.all(
          this.getGroupSelectedCategories.map(async category => {
            await CategoriesService.remove(category.guid);
          })
        ).then(() => {
          this.setGroupSelectedCategories([]);
          this.getAllCategories();
        });
      }
    },
    async onSave(data) {
      if (this.isGroupCreating) return;
      this.isGroupCreating = true;
      this.errorCreate = '';

      if (this.checkDoubles(data.title)) {
        this.errorCreate = `Group ${data.title} already exists`;
        this.isGroupCreating = false;
        return;
      }

      const payload = {
        payload: data,
      };

      GroupsService.create(payload)
        .then(() => {
          this.getGroupList();
        })
        .catch(err => {
          this.errorCreate = err;
        })
        .finally(() => {
          this.isGroupCreating = false;
        });
    },
    async updateGroup(group) {
      GroupsService.updateInfo(group.guid, { payload: group })
        .then(() => {
          this.getGroupList();
        })
        .catch(err => {
          this.errorCreate = err;
        });
    },
    async removeGroup(guid) {
      GroupsService.delete(guid)
        .then(() => {
          this.getGroupList();
          this.getAllCategories();
        })
        .catch(err => {
          this.errorCreate = err;
        });
    },
    async onCategoryDelete(group) {
      const _group = {
        guid: [group.guid],
        tags: group.tags,
        categories: group.categories,
      };

      GroupsService.setInfo(_group)
        .then(() => {
          this.getGroupList();
        })
        .catch(err => {
          this.errorCreate = err;
        });
    },
    async erase(group) {
      const payload = {
        guid: [group.guid],
        categories: [],
        tags: group.tags,
      };

      GroupsService.setInfo(payload)
        .then(this.onUpdatedGroup.bind(this))
        .catch(err => {
          this.errorCreate = err;
        });
    },
    async union() {
      const payload = {
        guid: [this.getSelectedGroup.guid],
        categories: this.getGroupSelectedCategories,
      };

      GroupsService.addInfo(payload)
        .then(this.onUpdatedGroup.bind(this))
        .catch(err => {
          this.errorCreate = err;
        });
    },
    checkDoubles(title) {
      return this.groups.find(item => item.title.toLowerCase() === title.toLowerCase());
    },
    checkUser() {
      if (this.isReviewer) {
        this.$router.push('/').then(() => {
          this.$router.go(0);
        });
      }
    },
    setNiche(niche) {
      this.setGroupNiche(niche);
      this.getSelectedGroup && this.setSelectedGroup(this.getSelectedGroup);
    },
    onFilterType(filter) {
      this.filterGroups = filter;
    },
    onUpdatedGroup() {
      this.setSelectedGroup(this.getSelectedGroup);
      this.setGroupSelectedCategories([]);
      this.getGroupList();
      this.getAllCategories();
    },
  },
  mounted() {
    this.getGroupList();
    this.getAllCategories();
  },
  beforeUnmount() {
    this.setGroupSelectedCategories([]);
    this.setGroupNiche('straight');

    if (this.getSelectedGroup) {
      this.setSelectedGroup(this.getSelectedGroup);
    }
  },
  watch: {
    isReviewer(newValue) {
      if (newValue) {
        this.checkUser();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.groups__controls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;

  & > span {
    color: #777;
  }
}

:deep .groups__controls * {
  max-height: 40px !important;
}

.group__controls-search {
  min-width: 430px;
}

.groups__no-groups {
  display: inline-block;
  padding: 20px;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

// Specials
:deep .layout-overview__area--right {
  height: calc(100vh - 200px);
}
</style>
