<template>
  <div class="tabs">
    <div
      v-for="(tab, index) in options"
      :key="index"
      class="tabs__tab"
      :class="{
        'tabs__tab--active': isCurrentMode(tab.value),
      }"
      @click="$emit('set-mode', tab.value)"
    >
      {{ tab.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTabs',
  props: {
    mode: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [
        {
          title: 'Select',
          value: 'select',
        },
        {
          title: 'Create new',
          value: 'create',
        },
      ],
    },
  },
  methods: {
    isCurrentMode(mode) {
      return this.mode === mode;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';

.tabs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.tabs__tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 25px;
  font-weight: bold;
  color: #777;
  cursor: pointer;
  border-bottom: 4px solid transparent;

  @include transition;

  &:hover {
    color: #000;
    border-color: #000;
  }
}

.tabs__tab--active {
  color: $themeColor;
  border-color: $themeColor;
  pointer-events: none;
}
</style>
