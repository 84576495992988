import { createStore } from 'vuex';

import Auth from '@/store/modules/Auth';
import Gallery from '@/store/modules/Gallery';
import Groups from '@/store/modules/Groups';
import Config from '@/store/modules/Config';

export default createStore({
  modules: {
    auth: Auth,
    config: Config,
    gallery: Gallery,
    groups: Groups,
  },
});
