<template>
  <template v-if="getGalleryPictures.length">
    <div class="image-picker-controls">
      <div class="image-picker-controls__control">
        <div
          class="image-picker-controls__switch"
          @click="onFilterSwitchClick"
          :class="{
            'image-picker-controls__switch--active': c_isOpenFilters,
          }"
        >
          <svg width="16" height="16">
            <use xlink:href="@/assets/icons.svg#filter-gear"></use>
          </svg>
          <span>Filter</span>
          <span>{{ filterStatusText }}</span>
        </div>
        <transition-group tag="div" name="list" class="image-picker-controls__active-filters">
          <span
            class="image-picker-controls__active-filter image-picker-controls__active-filter--inverted image-picker-controls__active-filter--model"
            v-for="model in getGalleryFilterModels"
            :key="model.guid"
            @click="setFilterModel(model)"
          >
            {{ model.title }}
          </span>
          <span
            class="image-picker-controls__active-filter image-picker-controls__active-filter--inverted"
            v-for="category in getGalleryFilterCategories"
            :key="category.guid"
            @click="setFilter(category)"
          >
            {{ category.title }}
          </span>
        </transition-group>
      </div>
      <app-switch :enabled="c_showTags" title="Show info" @switch="onSwitch" />
    </div>

    <section class="image-picker__filter-set" v-if="c_isOpenFilters">
      <transition-group tag="ul" name="list" class="image-picker__labels">
        <li
          class="image-picker__label image-picker__label--model"
          :class="{
            'image-picker__label--hidden': isActiveFilterModel(model),
          }"
          v-for="model in galleryInfo.models"
          :key="model.guid"
          @click="setFilterModel(model)"
        >
          <span>{{ model.title }}</span>
          <span
            class="image-picker__label-stat"
            :class="{
              'image-picker__label-stat--highlighted': getFilterCountPictures(model.guid, 'models'),
            }"
            >({{ getFilterCountPictures(model.guid, 'models') }})
          </span>
        </li>
        <li
          class="image-picker__label"
          :class="{
            'image-picker__label--hidden': isActiveFilter(category),
          }"
          v-for="category in galleryInfo.categories"
          :key="category.guid"
          @click="setFilter(category)"
        >
          <span>
            {{ category.title }}
          </span>
          <span
            class="image-picker__label-stat"
            :class="{
              'image-picker__label-stat--highlighted': getFilterCountPictures(category.guid, 'categories'),
            }"
          >
            ({{ getFilterCountPictures(category.guid, 'categories') }})
          </span>
        </li>
      </transition-group>
    </section>

    <section class="image-picker">
      <app-image
        v-for="(picture, index) in filteredPictures"
        :image="picture"
        :key="index"
        :image-url="getImageUrl(picture)"
        :index="index"
        :selected="isSelected(picture)"
        :showInfo="showTags"
        @select="select"
      />
    </section>
  </template>
  <app-loader v-else />
</template>

<script>
import AppImage from '@/modules/Image/Image.vue';
import AppLoader from '@/components/Loader.vue';
import AppSwitch from '@/components/Switch.vue';

import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'AppImagePicker',
  components: {
    AppImage,
    AppLoader,
    AppSwitch,
  },
  props: {
    galleryInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isOpenFilters: true,
    showTags: true,
  }),
  computed: {
    ...mapGetters([
      'getConfig',
      'getGalleryFilterCategories',
      'getGalleryFilterModels',
      'getGalleryInfo',
      'getGalleryPictures',
      'getGallerySelectedPictures',
      'getGalleryThumbs',
    ]),
    c_showTags() {
      return this.showTags;
    },
    c_isOpenFilters() {
      return (
        this.isOpenFilters &&
        (this.galleryInfo.tags.some(tag => !this.isActiveFilter(tag)) ||
          this.galleryInfo.models.some(model => !this.isActiveFilterModel(model)))
      );
    },
    filterStatusText() {
      return `Showing ${this.filteredPictures.length} of ${this.getGalleryPictures.length}`;
    },
    filteredPictures() {
      return this.getGalleryPictures.filter(picture => {
        return (
          this.getGalleryFilterCategories.every(category =>
            picture.categories?.some(_category => _category.guid === category.guid)
          ) && this.getGalleryFilterModels.every(model => picture?.models.some(_model => _model.guid === model.guid))
        );
      });
    },
  },
  methods: {
    ...mapMutations({
      select: 'setGallerySelectedPictures',
      setFilter: 'setGalleryFilterCategory',
      cleanFilters: 'cleanGalleryFilterTags',
      setFilterModel: 'setGalleryFilterModels',
    }),
    isActiveFilter(category) {
      return this.getGalleryFilterCategories.some(_category => _category.guid === category.guid);
    },
    isActiveFilterModel(model) {
      return this.getGalleryFilterModels.some(_model => _model.guid === model.guid);
    },
    isSelected(picture) {
      return !!this.getGallerySelectedPictures.find(selected => selected.guid === picture.guid);
    },
    getImageUrl(picture) {
      const found = this.getGalleryThumbs.find(thumb => thumb.md5 === picture.md5);

      const staticEndpoint = this.getConfig('staticEndpoint');

      if (!found || !staticEndpoint) return picture.url;

      return `${staticEndpoint}${found.galleryId}/t/${found.filename}`;
    },
    getFilterCountPictures(guid, type) {
      return (
        this.getGalleryPictures.filter(picture => {
          return picture[type].some(item => item.guid === guid);
        }).length || 0
      );
    },
    onSwitch(value) {
      this.showTags = value;
    },
    onFilterSwitchClick() {
      this.isOpenFilters = !this.isOpenFilters;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/transitions.scss';

.image-picker-controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}

.image-picker {
  display: block;
  column-count: 3;
  column-gap: 6px;
  column-fill: balance;

  @media (min-width: 1441px) {
    column-count: 4;
  }
}

.image-picker-controls__control {
  display: inline-flex;
  margin-right: auto;
}

.image-picker-controls__switch {
  display: flex;
  align-items: center;
  gap: 5px;
  user-select: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;

  &:hover {
    background-color: #ccc;
  }

  span + span {
    color: #777;
    margin-left: 5px;
    font-size: 0.8rem;
  }
}

.image-picker__filter-set {
  padding: 0;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.image-picker__labels {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  gap: 3px;
  margin-block: 5px;
}

.image-picker__label {
  @include group__label;
  color: #000;

  &--model::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 3px;
    background-color: #222;
    mask-size: 12px;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-image: url('@/assets/icons.svg#person');
  }

  &--hidden {
    display: none;
  }
}

.image-picker__label-stat {
  color: #888;
  font-size: 0.9rem;
}

.image-picker__label-stat--highlighted {
  color: $themeColor;
  font-weight: 500;
}

.image-picker-controls__active-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}

.image-picker-controls__active-filter {
  @include group__label;
  position: relative;

  &--model::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 3px;
    background-color: #fff;
    mask-size: 12px;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-image: url('@/assets/icons.svg#person');
  }

  &::after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #fff;
    mask-image: url('@/assets/icons.svg#cross');
    mask-size: 9px;
    mask-repeat: no-repeat;
    mask-position: center;
    opacity: 0.9;
    margin-left: 3px;
  }
}

@include list;
</style>
